import { apiBaseUrl, requestOptions } from '../../utils/requestOptions'
import { handleResponse } from '../../utils/handleResponse'
import moment from 'moment'

export const transactionsService = {
  getTransactions,
}

/**
 * @param {String} startDateInclusive The start date of the date range. This is a date in the format 'YYYY-MM-DD'.
 * @param {String} endDateExclusive The end date of the date range. This is a date in the format 'YYYY-MM-DD'.
 * @returns The transaction date retrieved for this date range.
 */
async function getTransactions(startDateInclusive, endDateExclusive) {
  const startDate = moment(startDateInclusive).startOf('day').utc().format()
  const endDate = moment(endDateExclusive).startOf('day').utc().format()

  const url =
    `${apiBaseUrl}/merchant/transactions?` +
    new URLSearchParams({
      utcStartDateInclusive: startDate,
      utcEndDateExclusive: endDate,
    })

  const response = await fetch(url, requestOptions.get())

  return handleResponse(response)
}
