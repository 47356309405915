import { apiBaseUrl, requestOptions } from '../../utils/requestOptions'
import { handleResponse } from '../../utils/handleResponse'

export const setupAccountService = {
  getSignupStatus,
  addBusinessInformation,
  addProofOfIdentity,
  addBankDetails,
  getLoyaltyPercentage,
  addLoyaltyPercentage,
}

async function getSignupStatus() {
  const response = await fetch(`${apiBaseUrl}/merchant/account/progress`, requestOptions.get())

  return handleResponse(response)
}

async function addBusinessInformation(businessInformation) {
  const response = await fetch(`${apiBaseUrl}/merchant/account/businessinformation`, requestOptions.post(businessInformation))

  return handleResponse(response)
}

async function addProofOfIdentity(proofOfIdentity) {
  const response = await fetch(`${apiBaseUrl}/merchant/account/proofofidentity`, requestOptions.postFileUpload(proofOfIdentity))

  return handleResponse(response)
}

async function addBankDetails(bankDetails) {
  const response = await fetch(`${apiBaseUrl}/merchant/account/bankAccount`, requestOptions.post(bankDetails))

  return handleResponse(response)
}

async function getLoyaltyPercentage() {
  const response = await fetch(`${apiBaseUrl}/merchant/account/loyaltyPercent`, requestOptions.get())

  return handleResponse(response)
}

async function addLoyaltyPercentage(loyaltyPercentageDetails) {
  const response = await fetch(`${apiBaseUrl}/merchant/account/loyaltyPercent`, requestOptions.post(loyaltyPercentageDetails))

  return handleResponse(response)
}
