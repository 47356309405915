import React from 'react'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { GenericCardWithHeader, Label3XLarge } from '@pixieme/pixie-react-components'

const DashboardPendingApproval = () => (
  <div>
    <Label3XLarge variant="dark" label="Welcome to Pixie!" additionalCss="font-extrabold tracking-tight" />
    <div className="py-8">
      <GenericCardWithHeader
        backgroundColor="bg-white"
        labelVariant="dark"
        iconVariant="success"
        headerVariant="dark"
        header="Your application is being processed"
        label={
          "Thank you for telling us about your business, the Pixie Team are now setting up your account. We'll send you an email when your account is ready to take payments."
        }
        icon={faCheckCircle}
        iconSize="2x"
      />
    </div>
  </div>
)

export default DashboardPendingApproval
