import {
  BusinessInformationRegisteredPostcodeValidationError,
  BusinessInformationTradingPostcodeValidationError,
  FormValidationApError,
  TelephoneValidationError,
} from '../services/pixie/Models/ApiModels'
import { ApiError } from '../models/models'

const IsApiGeneralError = (apiResponseModel: any): apiResponseModel is ApiError => apiResponseModel?.modelState !== undefined
const IsApiFormValidationError = (apiResponseModel: any): apiResponseModel is FormValidationApError => apiResponseModel?.modelState !== undefined
const IsApiTelephoneFormValidationError = (apiResponseModel: any): apiResponseModel is TelephoneValidationError => apiResponseModel?.modelState !== undefined
const IsBusinessInformationTradingPostcodeValidationError = (apiResponseModel: any): apiResponseModel is BusinessInformationTradingPostcodeValidationError =>
  apiResponseModel?.modelState['businessInformation.TradingAddress.Postcode'] !== undefined
const IsBusinessInformationRegisteredPostcodeValidationError = (
  apiResponseModel: any
): apiResponseModel is BusinessInformationRegisteredPostcodeValidationError =>
  apiResponseModel?.modelState['businessInformation.RegisteredAddress.Postcode'] !== undefined

export {
  IsApiGeneralError,
  IsApiFormValidationError,
  IsApiTelephoneFormValidationError,
  IsBusinessInformationTradingPostcodeValidationError,
  IsBusinessInformationRegisteredPostcodeValidationError,
}
