export async function getLatLongForPostcode(postcodeString) {
  const sanitisedPostcode = formatPostcodeString(postcodeString)

  const url =
    'https://nominatim.openstreetmap.org/search?' +
    new URLSearchParams({
      format: 'json',
      countrycodes: 'gb',
      postalcode: sanitisedPostcode,
    })

  return fetch(url, {
    method: 'GET',
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.length === 1) {
        return {
          lat: data[0].lat,
          lon: data[0].lon,
        }
      }
    })
}

function formatPostcodeString(postcodeString) {
  const postcodeMatches = postcodeString.match(/^([a-zA-Z0-9]+)\s*([a-zA-Z0-9]{3})$/)
  const firstPart = postcodeMatches[1]
  const secondPart = postcodeMatches[2]
  const sanitisedPostcode = `${firstPart.toUpperCase()} ${secondPart.toUpperCase()}`

  return sanitisedPostcode
}
