import React, { FC } from 'react'

export type PixieLogoSize = 'xsmall' | 'small' | 'medium' | 'large'

type PixieLogoProps = {
  size?: PixieLogoSize
}

const PixieLogo: FC<PixieLogoProps> = ({ size = 'small' }) => {
  const height = () => {
    switch (size) {
      case 'xsmall':
        return '25px'
      case 'small':
        return '80px'
      case 'medium':
        return '100px'
      case 'large':
        return '120px'
      default:
        return '100px'
    }
  }

  return (
    <svg height={height()} viewBox="0 0 113 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Pixie Merchant Portal Logo</title>
      <g id="Pixie-Merchant-Portal-Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Combined-Shape" fill="#DF5142">
          <path d="M9.93638468,0.000381084689 C11.9827952,0.0193179464 37.9181714,0.727719388 55.6803992,24.3122529 C65.2215039,36.9861267 68.3219764,51.5533536 67.9740431,61.3292379 C67.8478188,64.8048828 67.5102573,68.0840049 66.9695357,71.1980756 C67.2605507,70.319172 67.5699336,69.3600398 67.9016858,68.3080983 C69.621216,68.7071332 71.4635699,68.5889006 73.2192247,67.8573368 C77.8142718,65.9286684 80.0034216,60.5564772 78.1177183,55.8641231 C76.7305343,52.3984316 73.5009964,50.2776353 70.061936,50.1741819 C70.061936,50.1741819 81.2677821,25.973458 111.193388,15 C111.193388,15 118.996298,38.9347006 102.169467,62.5516513 C87.799107,82.7103001 66.8974224,84.6020208 63.1621403,85.1636254 C65.1200928,86.6119741 69.9896868,89.5234505 73.6960692,98.0509727 C76.752209,105.093199 75.2422014,113 75.2422014,113 C63.4238268,108.387478 60.6434735,97.3565393 60.1109161,90.4518447 C58.6099947,93.0961951 56.9054992,95.6380849 55.0077281,98.1143838 C41.4847199,115.772799 21.2040734,120 21.2040734,120 C18.6139032,102.789807 30.5905411,90.8810022 40.1393777,88.176212 C40.1393777,88.176212 17.1293877,81.4219647 6.24294083,58.6089919 C-9.64601396,25.3014333 9.80732437,-5.92942037e-15 9.80732437,-5.92942037e-15 L9.80732437,-5.92942037e-15 Z" />
        </g>
      </g>
    </svg>
  )
}

export default PixieLogo
