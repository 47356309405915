import React, { Dispatch, FC, useContext, useEffect } from 'react'
import { useImmerReducer } from 'use-immer'
import { useHistory } from 'react-router-dom'
import { LabelMedium } from '@pixieme/pixie-react-components'
import Login from './components/Login'
import Signup from './components/Signup'
import RecoverPassword from './components/RecoverPassword'
import { AuthPageActions, AuthPageReducer, AuthPageReducerInitialState, AuthPageState, CurrentStateType } from './Context/AuthPageReducer'
import PixieLogo from '../../../appComponents/PixieLogo'

export const AuthPageStateContext = React.createContext<AuthPageState>({} as AuthPageState)
export const AuthPageDispatchContext = React.createContext<Dispatch<AuthPageActions>>({} as Dispatch<AuthPageActions>)

const AuthPage = () => {
  const [state, dispatch] = useImmerReducer(AuthPageReducer, AuthPageReducerInitialState)
  const history = useHistory()
  const currentTab = (): TabType => {
    switch (state.currentState) {
      case 'login':
        return 'login'
      case 'signUp':
        return 'signUp'
      case 'recoverPassword':
        return 'recoverPassword'
      default:
        return 'login'
    }
  }

  useEffect(() => {
    history.replace('/')
  }, [])

  return (
    <AuthPageDispatchContext.Provider value={dispatch}>
      <AuthPageStateContext.Provider value={state}>
        <div className="sm:flex sm:items-center sm:justify-center sm:min-h-screen mt-10 mx-4 sm:mx-auto sm:max-w-2xl mb-4 sm:my-4">
          <div className="w-full">
            <div className="flex justify-center mb-8 sm:mb-12">
              <PixieLogo />
            </div>
            <div className="bg-white w-full shadow-sm rounded-md">
              <TabHeader currentTab={currentTab()} currentState={state.currentState} />
              <div className="px-4 py-8 sm:px-8 sm:py-8">
                {state.currentState === 'login' && <Login />}
                {state.currentState === 'signUp' && <Signup />}
                {state.currentState === 'recoverPassword' && <RecoverPassword />}
              </div>
            </div>
          </div>
        </div>
      </AuthPageStateContext.Provider>
    </AuthPageDispatchContext.Provider>
  )
}

const TabHeader: FC<TabHeaderProps> = ({ currentTab, currentState }) => {
  const dispatch = useContext(AuthPageDispatchContext)

  return (
    <>
      {(currentState === 'signUp' || currentState === 'login') && (
        <div className="flex space-x-8 justify-center border-b border-gray-200 pt-8">
          <Tab onClick={() => dispatch({ type: 'showLogin' })} thisTab="login" label="Login" currentTab={currentTab} />
          <Tab onClick={() => dispatch({ type: 'showSignUp' })} thisTab="signUp" label="Create New Account" currentTab={currentTab} />
        </div>
      )}
      {currentState === 'recoverPassword' && (
        <>
          <div className="flex space-x-8 justify-center border-b border-gray-200 pt-8">
            <Tab onClick={() => dispatch({ type: 'showRecoverPassword' })} thisTab="recoverPassword" label="Recover Password" currentTab={currentTab} />
          </div>
        </>
      )}
    </>
  )
}

const Tab: FC<TabProps> = ({ onClick, thisTab, label, currentTab }) => (
  <button type="button" onClick={onClick}>
    <LabelMedium
      variant={currentTab === thisTab ? 'dark' : 'grey'}
      label={label}
      additionalCss={['font-bold border-b-2 pb-2', currentTab === thisTab ? 'border-primary' : 'border-transparent'].join(' ')}
    />
  </button>
)

/* Types */
type TabType = 'login' | 'signUp' | 'recoverPassword'
type TabHeaderProps = {
  currentTab: TabType
  currentState: CurrentStateType
}

type TabProps = {
  onClick: () => void
  thisTab: TabType
  label: string
  currentTab: TabType
}

export default AuthPage
