const DashboardReducer = (state: DashboardState, action: DashboardActions) => {
  switch (action.type) {
    case 'signupCompleted':
      break
    case 'discoverCompleted':
      break
    case 'step1AccountSetup':
      break
    case 'step2BusinessProfileSetup':
      break
    default:
  }
}

export type DashboardState = {
  businessState: BusinessStateType
  BusinessSignupProgress: BusinessSignupProgressType
}

const DashboardInitialState: DashboardState = {
  businessState: 'signingUp',
  BusinessSignupProgress: 'notStarted',
}

export type DashboardActions =
  | { type: 'signupCompleted' }
  | { type: 'discoverCompleted' }
  | { type: 'step1AccountSetup' }
  | { type: 'step2BusinessProfileSetup' }

type BusinessStateType = 'signingUp' | 'pendingApproval' | 'approved'
type BusinessSignupProgressType = 'notStarted' | 'signupComplete' | 'discoverComplete'

export { DashboardReducer, DashboardInitialState }
