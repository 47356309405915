const AuthPageReducer = (state: AuthPageState, action: AuthPageActions) => {
  switch (action.type) {
    case 'showLogin':
      state.currentState = 'login'
      return
    case 'showSignUp':
      state.currentState = 'signUp'
      return
    case 'showRecoverPassword':
      state.currentState = 'recoverPassword'
      state.recoverEmail = ''
      return
    case 'showTermsAndConditions':
      return
    case 'loginChangeEmail':
      state.loginEmail = action.email
      return
    case 'loginChangePassword':
      state.loginPassword = action.password
      return
    case 'signupChangeEmail':
      state.signUpEmail = action.email
      return
    case 'signupChangePassword':
      state.signUpPassword = action.password
      return
    case 'signUpChangeAgreeTermsAndConditions':
      state.signUpAgreeTermsAndConditions = action.value
      return
    case 'signUpChangeAgreeKeepMeInformed':
      state.optInToMarketing = action.value
      return
    case 'recoverPasswordChangeEmail':
      state.recoverEmail = action.email
      break
    case 'login':
      break
    case 'signUp':
      break
    case 'recoverPassword':
      break
    default:
      break
  }
}

export type CurrentStateType = 'login' | 'signUp' | 'recoverPassword'

export type AuthPageState = {
  currentState: CurrentStateType
  loginEmail: string
  loginPassword: string
  signUpEmail: string
  signUpPassword: string
  signUpAgreeTermsAndConditions: boolean
  optInToMarketing: boolean
  recoverEmail: string
}

const AuthPageReducerInitialState: AuthPageState = {
  currentState: 'login',
  loginEmail: '',
  loginPassword: '',
  signUpEmail: '',
  signUpPassword: '',
  signUpAgreeTermsAndConditions: false,
  optInToMarketing: true,
  recoverEmail: '',
}

export type AuthPageActions =
  | { type: 'showLogin' }
  | { type: 'showSignUp' }
  | { type: 'showRecoverPassword' }
  | { type: 'showTermsAndConditions' }
  | { type: 'login' }
  | { type: 'signUp' }
  | { type: 'recoverPassword' }
  | { type: 'loginChangeEmail'; email: string }
  | { type: 'loginChangePassword'; password: string }
  | { type: 'signupChangeEmail'; email: string }
  | { type: 'signupChangePassword'; password: string }
  | { type: 'signUpChangeAgreeTermsAndConditions'; value: boolean }
  | { type: 'signUpChangeAgreeKeepMeInformed'; value: boolean }
  | { type: 'recoverPasswordChangeEmail'; email: string }

export { AuthPageReducerInitialState, AuthPageReducer }
