import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Joi, { ObjectSchema, ValidationErrorItem } from 'joi'
import { DangerInformationCard, InputMedium, LabelExtraSmall, LabelMedium, TransparentButton } from '@pixieme/pixie-react-components'
import { AuthPageDispatchContext, AuthPageStateContext } from '../AuthPage'
import { CreateAccountAction } from '../../../../redux/reducers/UserAccount/UserAccountActionCreators'
import { useAppSelector } from '../../../../redux/Hooks'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import ValidationCard from '../../../../appComponents/cards/ValidationCard'

const Signup = () => {
  const { creatingAccount, createAccountError } = useAppSelector(({ userAccountReducer: reducer }) => reducer)
  const localDispatch = useContext(AuthPageDispatchContext)
  const localState = useContext(AuthPageStateContext)
  const [formValidationErrors, setFormValidationErrors] = useState<ValidationErrorItem[]>([])
  const SignupDisabled = !(localState.signUpEmail && localState.signUpPassword && localState.signUpAgreeTermsAndConditions)
  const appDispatch = useDispatch()

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const validationErrors = ValidateSchema(ValidationSchema(), localState.signUpEmail, localState.signUpPassword, localState.signUpAgreeTermsAndConditions)

    if (validationErrors.length === 0) {
      setFormValidationErrors([])
      appDispatch(CreateAccountAction(localState.signUpEmail, localState.signUpPassword, localState.optInToMarketing))
    } else {
      setFormValidationErrors(validationErrors)
    }
  }

  return (
    <div className="w-full">
      <form>
        <div className="bg-inputBackground py-1 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Email" additionalCss="font-extrabold uppercase" />
          <InputMedium
            inputType="email"
            value={localState.signUpEmail}
            onChange={(e) => localDispatch({ type: 'signupChangeEmail', email: e.currentTarget.value })}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.email} />
        </div>
        <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Password" additionalCss="font-extrabold uppercase" />
          <InputMedium
            inputType="password"
            value={localState.signUpPassword}
            onChange={(e) => localDispatch({ type: 'signupChangePassword', password: e.currentTarget.value })}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.password} />
        </div>
        <div className="my-4">
          <div className="flex items-center">
            <label htmlFor="signUpAgreeTermsAndConditions" className="text-dark text-sm flex items-center">
              <input
                type="checkbox"
                id="signUpAgreeTermsAndConditions"
                className="mr-2 outline-none focus:ring-0 text-primary"
                checked={localState.signUpAgreeTermsAndConditions}
                onChange={(e) => localDispatch({ type: 'signUpChangeAgreeTermsAndConditions', value: e.currentTarget.checked })}
              />
              <LabelMedium variant="dark" label="I agree to" />
            </label>
            <TransparentButton
              label="Terms of use."
              size="medium"
              onClick={() => window.open('https://app.pixieapp.co/Content/legal/BusinessTermsAndConditions.pdf', '_blank')}
              additionalCss="px-0 pl-1"
              additionalLabelCss="text-primary"
            />
          </div>
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.agreeTermsAndConditions} />
          <div className="flex items-center">
            <label htmlFor="optInToMarketing" className="text-dark text-sm flex items-center">
              <input
                type="checkbox"
                id="optInToMarketing"
                className="mr-2 outline-none focus:ring-0 text-primary"
                checked={localState.optInToMarketing}
                onChange={(e) => localDispatch({ type: 'signUpChangeAgreeKeepMeInformed', value: e.currentTarget.checked })}
              />
              <LabelMedium variant="dark" label="Keep me informed about Pixie products and services." />
            </label>
          </div>
        </div>
        {createAccountError && (
          <div className="my-4">
            <DangerInformationCard icon={faInfoCircle} iconSize="2x" label={createAccountError.description} />
          </div>
        )}
        <div className="flex justify-center mt-8">
          <BusyButton
            isBusy={creatingAccount}
            label="Sign Up"
            disabled={SignupDisabled}
            size="small"
            onClick={(e) => validateAndSubmit(e)}
            roundedType="full"
            additionalCss="px-6 hover:bg-red-600"
          />
        </div>
      </form>
    </div>
  )
}

export default Signup

/* validation logic */
const validationFieldKeys = {
  email: 'email',
  password: 'password',
  agreeTermsAndConditions: 'agreeTermsAndConditions',
}

const ValidationSchema = (): ObjectSchema =>
  Joi.object().keys({
    agreeTermsAndConditions: Joi.boolean().invalid(false).messages({ 'any.invalid': 'You must agree to the terms of use to continue' }),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } })
      .messages({
        'string.empty': 'Email is required',
        'string.email': 'You must enter a valid email',
      }),
    // TODO: check password validation policy and adjust this.
    password: Joi.string()
      .regex(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)
      .required()
      .messages({
        'string.empty': 'Password is required',
        'string.pattern.base': 'Password must be at least 8 characters, with at least one number and one letter.',
      }),
  })

const ValidateSchema = (schema: ObjectSchema, email: string, password: string, agreeTermsAndConditions: boolean): ValidationErrorItem[] => {
  const { error } = schema.validate({ email, password, agreeTermsAndConditions }, { abortEarly: false })
  return error?.details ?? []
}
