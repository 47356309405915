import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Joi, { ObjectSchema, ValidationErrorItem } from 'joi'
import { LabelSmall, PrimaryButton, TransparentButton } from '@pixieme/pixie-react-components'
import SetupHeaderCard from '../../../../appComponents/SetupHeader/SetupHeaderCard'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import { useAppSelector } from '../../../../redux/Hooks'
import ValidationCard from '../../../../appComponents/cards/ValidationCard'
import AppConfig from '../../../../config/appConfig'
import { AddProofOfIdentityAction } from '../../../../redux/reducers/SetupAccount/SetupAccountActionCreators'

const AccountDetailsProofOfIdentity = React.forwardRef<HTMLDivElement, AccountDetailsProofOfIdentityProps>(({ enabled, completed }, ref) => (
  <div ref={ref} className="bg-white w-full shadow-sm rounded-md" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
    <SetupHeaderCard completed={completed} enabled={enabled} labelLeft="Proof of identity" labelRight="Step 2 of 4" openState="closed">
      <AccountDetailsBusinessForm />
    </SetupHeaderCard>
  </div>
))

export default AccountDetailsProofOfIdentity

const AccountDetailsBusinessForm = () => {
  const {
    status: { addingProofOfIdentity, addProofOfIdentityErrors },
  } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)
  const [formValidationErrors, setFormValidationErrors] = useState<ValidationErrorItem[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [imageProof, setImageProof] = useState<File | null>(null)
  const dispatch = useDispatch()

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const validationErrors = ValidateSchema(ValidationSchema(), imageProof?.name ?? '', imageProof?.size ?? 0)

    if (validationErrors.length === 0) {
      setFormValidationErrors([])
      dispatch(AddProofOfIdentityAction(imageProof!))
    } else {
      setFormValidationErrors(validationErrors)
    }
  }

  const handleImageUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length >= 1) {
      setImageProof(e.target.files[0])
    }
  }

  return (
    <div className="px-4 pb-6">
      <form>
        <LabelSmall variant="dark" label="We require proof of identity in order to create your Pixie account." />
        <LabelSmall variant="dark" label="IF THIS IS A LTD COMPANY:" additionalCss="font-bold mt-6" />
        <LabelSmall variant="dark" label="We require a scan or photo of:" additionalCss="mt-2" />
        <ul className="list-disc list-inside">
          <li>
            <LabelSmall variant="dark" label={"At least one directors' driving licence or passport, as proof of identity."} additionalCss="inline" />
          </li>
        </ul>
        <LabelSmall variant="dark" label="IF THIS IS A SOLE TRADER OR PARTNERSHIP:" additionalCss="font-bold mt-6" />
        <LabelSmall variant="dark" label="We require a scan or photo of:" additionalCss="mt-2" />
        <ul className="list-disc list-inside space-y-1">
          <li>
            <LabelSmall
              variant="dark"
              label="The sole trader’s/partners’ driving licence or passport. It must show the full name, photograph and date of birth. Note: Provisional licences are not accepted."
              additionalCss="inline"
            />
          </li>
          <li>
            <LabelSmall
              variant="dark"
              label="OR a government-issued document without a photo, plus a utility bill/council tax letter (the latter must feature the sole trader’s/partners’ home address)"
              additionalCss="inline"
            />
          </li>
        </ul>
        <LabelSmall variant="dark" label="NOTE" additionalCss="font-bold mt-6" />
        <LabelSmall
          variant="dark"
          label="Please photograph all documents in one single photo and upload using this form. The maximum file size is 15MB"
          additionalCss="mt-2"
        />
        <div className="p-4 w-full bg-gray-100 mt-6 rounded-md hover:bg-gray-200/70">
          <button type="button" onClick={() => fileInputRef?.current?.click()} className="w-full">
            <div className="flex flex-col items-start">
              <PrimaryButton label="Upload file" size="small" roundedType="full" additionalCss="block" />
              <LabelSmall variant="dark" label="JPEG, GIF and PNG formats are accepted." additionalCss="mt-2" />
            </div>
          </button>
          {imageProof && (
            <div className="flex space-x-4 mt-4 items-center">
              <LabelSmall variant="dark" label={imageProof.name} />
              <TransparentButton label="Delete" size="small" roundedType="full" additionalLabelCss="text-primary" onClick={() => setImageProof(null)} />
            </div>
          )}
          <input
            accept="image/png, image/jpeg, image/gif"
            size={10}
            onChange={handleImageUploadChange}
            multiple={false}
            ref={fileInputRef}
            type="file"
            hidden
          />
        </div>
        <ValidationCard errors={formValidationErrors} path={validationFieldKeys.uploadedFile} />
        <ValidationCard errors={formValidationErrors} path={validationFieldKeys.uploadedFileSize} />
        <ValidationCard
          errors={addProofOfIdentityErrors.map((item) => ({ message: item.message, path: [item.path] } as ValidationErrorItem))}
          path="uploadImage"
        />
        <BusyButton
          isBusy={addingProofOfIdentity}
          label="Save and continue"
          size="small"
          roundedType="full"
          additionalCss="mt-6"
          onClick={(e) => validateAndSubmit(e)}
        />
      </form>
    </div>
  )
}

/* types */
type AccountDetailsProofOfIdentityProps = {
  enabled: boolean
  completed: boolean
}

/* validation logic */
const validationFieldKeys = {
  uploadedFile: 'uploadedFile',
  uploadedFileSize: 'uploadedFileSize',
}

const ValidationSchema = (): ObjectSchema =>
  Joi.object().keys({
    uploadedFile: Joi.string().empty().messages({ 'string.empty': 'Please add an identity document to continue.' }),
    uploadedFileSize: Joi.number().less(15000000).messages({ 'number.less': 'The size of the proof of identity file needs to be less than 15MB' }),
  })

const ValidateSchema = (schema: ObjectSchema, uploadedFile: string, uploadedFileSize: number): ValidationErrorItem[] => {
  const { error } = schema.validate({ uploadedFile, uploadedFileSize }, { abortEarly: false })
  return error?.details ?? []
}
