import { combineReducers } from 'redux'

import { AppReducer } from './reducers/App/AppReducer'
import { AuthReducer } from './reducers/Authentication/AuthReducer'
import { UserAccountReducer } from './reducers/UserAccount/UserAccountReducer'
import { SetupAccountReducer } from './reducers/SetupAccount/SetupAccountReducer'
import { SetupDiscoveryReducer } from './reducers/SetupDiscovery/SetupDiscoveryReducer'

export default combineReducers({
  appReducer: AppReducer,
  authReducer: AuthReducer,
  userAccountReducer: UserAccountReducer,
  setupAccountReducer: SetupAccountReducer,
  setupDiscoveryReducer: SetupDiscoveryReducer,
})
