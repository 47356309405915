import { LOCAL_STORAGE_CURRENT_USER_KEY } from '../redux/reducers/Authentication/AuthReducer'

export function handleResponse(response) {
  console.log('handleresponse', response)

  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      console.log('non success response!!')
      if ([401, 403].indexOf(response.status) !== -1) {
        // Auto-logout if 401 Unauthorized or 403 Forbidden response returned from api.
        console.log('Redirecting to login!!')
        localStorage.removeItem(LOCAL_STORAGE_CURRENT_USER_KEY)
        window.location.replace('/')
      }

      const error = data || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
