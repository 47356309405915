import { apiBaseUrl, requestOptions } from '../../utils/requestOptions'
import { handleResponse } from '../../utils/handleResponse'

export const dashboardService = {
  getDashboardData,
}

async function getDashboardData() {
  const response = await fetch(`${apiBaseUrl}/merchant/dashboard`, requestOptions.get())
  return handleResponse(response)
}
