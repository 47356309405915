import React, { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { DangerInformationCard, InputMedium, LabelExtraSmall, LabelSmall, OpeningHourLineModel, OpeningHoursEditor } from '@pixieme/pixie-react-components'
import Joi, { ObjectSchema, ValidationErrorItem } from 'joi'
import { DiscoverySetupDispatchContext, DiscoverySetupStateContext } from '../index'
import { AddBasicsAction } from '../../../../redux/reducers/SetupDiscovery/SetupDiscoveryActionCreators'
import { useAppSelector } from '../../../../redux/Hooks'
import SetupHeaderCard from '../../../../appComponents/SetupHeader/SetupHeaderCard'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import ValidationCard from '../../../../appComponents/cards/ValidationCard'
import AppConfig from '../../../../config/appConfig'

const DiscoveryDetailsBasics = React.forwardRef<HTMLDivElement, DiscoveryDetailsBasicsProps>(({ enabled, completed, showOpeningHours }, ref) => (
  <div ref={ref} className="bg-white w-full shadow-sm rounded-md" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
    <SetupHeaderCard completed={completed} enabled={enabled} labelLeft="Basics" labelRight="Step 1 of 4" openState="closed">
      <div className="px-4 pb-6">
        <DiscoveryDetailsBasicsForm showOpeningHours={showOpeningHours} />
      </div>
    </SetupHeaderCard>
  </div>
))

export default DiscoveryDetailsBasics

export const DiscoveryDetailsBasicsForm: FC<DiscoveryDetailsBasicsFormProps> = ({ editMode = 'Signup', showOpeningHours }) => {

  const {
    status: { addingBasicsFormValidationApiErrors, addingBasics },
  } = useAppSelector(({ setupDiscoveryReducer: reducer }) => reducer)
  const [formValidationErrors, setFormValidationErrors] = useState<ValidationErrorItem[]>([])
  const localState = useContext(DiscoverySetupStateContext)
  const localDispatch = useContext(DiscoverySetupDispatchContext)
  const appDispatch = useDispatch()

  const openingTimesChangedHandler = (updatedOpeningTimes: OpeningHourLineModel[]) => {
    localDispatch({
      type: 'discoveryBasicsTimeInformationChanged',
      times: updatedOpeningTimes,
    })
  }

  const validateAndSubmit = () => {
    const validationErrors = ValidateSchema(
      ValidationSchema(),
      localState.discoveryBasicsInformation.businessPhoneNumber,
      localState.discoveryBasicsInformation.businessWebsite
    )

    if (validationErrors.length === 0) {
      setFormValidationErrors([])
      appDispatch(AddBasicsAction(localState.discoveryBasicsInformation))
    } else {
      setFormValidationErrors(validationErrors)
    }
  }

  return (
    <div className="">
      <LabelSmall
        variant="dark"
        label={
          editMode === 'Signup'
            ? "Let's start with some basics about your business."
            : 'The details you provide here will be displayed to users of the Pixie app and Website to help people discover your business.'
        }
      />
      <div className="bg-inputBackground py-1 mt-6 px-2 rounded-sm">
        <LabelExtraSmall variant="primary" label="Business Phone Number" additionalCss="font-extrabold uppercase" />
        <InputMedium
          inputType="tel"
          placeHolder="01225 567890"
          value={localState.discoveryBasicsInformation.businessPhoneNumber}
          onChange={(e) =>
            localDispatch({
              type: 'discoveryBasicsInformationChanged',
              discoveryBasicsInformation: { ...localState.discoveryBasicsInformation, businessPhoneNumber: e.target.value },
            })
          }
        />
        <ValidationCard errors={formValidationErrors} path={validationFieldKeys.telephone} />
        <ValidationCard
          errors={addingBasicsFormValidationApiErrors.map((item) => ({ message: item.message, path: [item.path] } as ValidationErrorItem))}
          path="telephone"
        />
      </div>
      <div className="bg-inputBackground py-1 mt-8 px-2 rounded-sm">
        <LabelExtraSmall variant="primary" label="Business Website Url" additionalCss="font-extrabold uppercase" />
        <InputMedium
          inputType="url"
          value={localState.discoveryBasicsInformation.businessWebsite}
          placeHolder="https://www.website.com"
          onChange={(e) =>
            localDispatch({
              type: 'discoveryBasicsInformationChanged',
              discoveryBasicsInformation: { ...localState.discoveryBasicsInformation, businessWebsite: e.target.value },
            })
          }
        />
        <ValidationCard errors={formValidationErrors} path={validationFieldKeys.website} />
      </div>

      {showOpeningHours && (
        <>
          <LabelSmall variant="dark" label="Opening Hours" additionalCss="uppercase font-extrabold mt-6" />
          <LabelSmall
            variant="dark"
            label="The days your business is open. Use the Notes field to specify any additional information relating to your opening hours."
          />
          <div className="my-4">
            <OpeningHoursEditor times={localState.discoveryBasicsInformation.OpeningHours} openingTimesChanged={openingTimesChangedHandler} />
          </div>
          <div className="bg-inputBackground py-1 mt-8 px-2 rounded-sm">
            <LabelExtraSmall variant="primary" label="Notes" additionalCss="font-extrabold uppercase" />
            <InputMedium
              inputType="text"
              value={localState.discoveryBasicsInformation.openingHoursNotes}
              onChange={(e) =>
                localDispatch({
                  type: 'discoveryBasicsInformationChanged',
                  discoveryBasicsInformation: { ...localState.discoveryBasicsInformation, openingHoursNotes: e.target.value },
                })
              }
            />
          </div>
        </>
      )}

      {(addingBasicsFormValidationApiErrors?.length > 0 || formValidationErrors.length > 0) && (
        <div className="mt-6">
          <DangerInformationCard
            icon={faInfoCircle}
            iconSize="2x"
            label="There are some issues with the information you've entered above. Please fix these issues and try again."
          />
        </div>
      )}
      <BusyButton
        isBusy={addingBasics}
        buttonType="button"
        label={editMode === 'Edit' ? 'Save' : 'Save and continue'}
        size="small"
        roundedType="full"
        additionalCss={editMode === 'Edit' ? 'mt-6 px-8' : 'mt-6'}
        onClick={() => validateAndSubmit()}
      />
    </div>
  )
}

/* types */
type DiscoveryDetailsBasicsProps = {
  enabled: boolean
  completed: boolean
  showOpeningHours: boolean
}

type DiscoveryDetailsBasicsFormProps = {
  editMode?: EditMode
  showOpeningHours: boolean
}

type EditMode = 'Signup' | 'Edit'

/* validation logic */
const validationFieldKeys = {
  telephone: 'telephone',
  website: 'website',
}

const ValidationSchema = (): ObjectSchema =>
  Joi.object().keys({
    telephone: Joi.string()
      .regex(/^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/)
      .messages({
        'string.pattern.base': 'Please enter a valid phone number.',
      }),
    website: Joi.string().uri().allow(null, '').messages({
      'string.uri': 'Please enter a valid website url prefixed with https:// or http://',
      'string.uriCustomScheme': 'Please enter a valid website url prefixed with https:// or http://',
    }),
  })

const ValidateSchema = (schema: ObjectSchema, telephone: string, website: string): ValidationErrorItem[] => {
  const { error } = schema.validate({ telephone, website }, { abortEarly: false })
  return error?.details ?? []
}
