import { UserAccountActions } from './UserAccountActionCreators'
import { UserHasABusinesses, UserHasAPendingBusinesses } from '../../../helpers/UserAccountHelpers'

const UserAccountReducer = (state: UserAccountState = UserAccountStateInitialState, action: UserAccountActions) => {
  switch (action.type) {
    case 'resetStoreUserAccount':
      return UserAccountStateInitialState
    case 'createAccountRequest':
      return {
        ...state,
        creatingAccount: true,
        createAccountError: null,
      }
    case 'createAccountSuccess':
      return {
        ...state,
        creatingAccount: false,
        createAccountError: null,
      }
    case 'createAccountFailure':
      return {
        ...state,
        creatingAccount: false,
        createAccountError: { error: action.error, description: action.description },
      }
    case 'resetPasswordForm':
      return {
        ...state,
        resetPasswordSuccess: false,
        resetPasswordError: null,
      }
    case 'resetPasswordRequest':
      return {
        ...state,
        resettingPassword: true,
        resetPasswordSuccess: false,
        resetPasswordError: null,
      }
    case 'resetPasswordSuccess':
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: true,
        resetPasswordError: null,
      }
    case 'resetPasswordFailure':
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: false,
        resetPasswordError: { error: action.error, description: action.description },
      }
    case 'getAccountDetailsRequest':
      return {
        ...state,
        gettingAccountDetails: true,
      }
    case 'getAccountDetailsSuccess':
      return {
        ...state,
        gettingAccountDetails: false,
        userAccount: action.userAccount,
        userHasABusiness: UserHasABusinesses(action.userAccount),
        userHasAPendingBusiness: UserHasAPendingBusinesses(action.userAccount),
      }
    case 'getAccountDetailsFailure':
      return {
        ...state,
        gettingAccountDetails: false,
      }
    default:
      return state
  }
}

export type UserAccountState = {
  creatingAccount: boolean
  resettingPassword: boolean
  gettingAccountDetails: boolean | null
  signupInProgress: boolean | null
  createAccountError: { error: string; description: string } | null
  resetPasswordError: { error: string; description: string } | null
  resetPasswordSuccess: boolean | null
  userAccount: UserAccount | null
  userHasABusiness: boolean
  userHasAPendingBusiness: boolean
}

const UserAccountStateInitialState: UserAccountState = {
  creatingAccount: false,
  resettingPassword: false,
  gettingAccountDetails: false,
  signupInProgress: false,
  createAccountError: null,
  resetPasswordError: null,
  resetPasswordSuccess: false,
  userAccount: null,
  userHasABusiness: false,
  userHasAPendingBusiness: false,
}

export type UserAccount = {
  businesses: Business[]
  pendingBusinesses: PendingBusiness[]
  firstName: string
  lastName: string
  bio: string
  username: string
  profilePhotoUrl: string
}

export type Business = {
  businessId: string
  name: string
  hasMadeTransactions: boolean
  merchantId: string
}

export type PendingBusiness = {
  pendingBusinessId: string
  name: string
  signupComplete: boolean
  discoveryComplete: boolean
  pendingApproval: boolean
}

export { UserAccountReducer }
