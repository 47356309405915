import { OpeningHourLineModel } from '@pixieme/pixie-react-components'
import { OpeningHours, SetupBusinessInformationRequest } from '../services/pixie/Models/ApiModels'
import { BusinessInformation } from '../models/models'
import OpeningHoursParser from './openingHoursParser'

export default class Mappers {
  MapToSetupBusinessInformationRequest = (businessInfo: BusinessInformation): SetupBusinessInformationRequest =>
    ({
      ConfirmedIndependent: businessInfo.ConfirmedIndependent,
      BusinessName: businessInfo.BusinessName,
      TradingType: businessInfo.TradingType,
      'TradingAddress.AddressLine1': businessInfo.TradingAddress.AddressLine1,
      'TradingAddress.AddressLine2': businessInfo.TradingAddress.AddressLine2,
      'TradingAddress.City': businessInfo.TradingAddress.City,
      'TradingAddress.County': businessInfo.TradingAddress.County,
      'TradingAddress.Postcode': businessInfo.TradingAddress.Postcode,
      IsARegisteredBusiness: businessInfo.IsARegisteredBusiness,
      IncorporationDate: businessInfo.IncorporationDate,
      RegistrationNumber: businessInfo.RegistrationNumber,
      LegalName: businessInfo.LegalName,
      VatNumber: businessInfo.VatNumber,
      'RegisteredAddress.AddressLine1': businessInfo.RegisteredAddress.AddressLine1,
      'RegisteredAddress.AddressLine2': businessInfo.RegisteredAddress.AddressLine2,
      'RegisteredAddress.City': businessInfo.RegisteredAddress.City,
      'RegisteredAddress.County': businessInfo.RegisteredAddress.County,
      'RegisteredAddress.Postcode': businessInfo.RegisteredAddress.Postcode,
      BusinessLocationLatitude: businessInfo.BusinessLocationLatitude,
      BusinessLocationLongitude: businessInfo.BusinessLocationLongitude,
    } as SetupBusinessInformationRequest)

  MapOpeningHoursResponseToOpeningHours = (openingHours?: OpeningHours): OpeningHourLineModel[] => new OpeningHoursParser().parse(openingHours)
}
