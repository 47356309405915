import { LOCAL_STORAGE_CURRENT_USER_KEY } from '../redux/reducers/Authentication/AuthReducer'

export const apiBaseUrl = process.env.REACT_APP_PIXIE_API_URL
export const apiClientId = process.env.REACT_APP_PIXIE_API_CLIENT_ID
export const apiClientSecret = process.env.REACT_APP_PIXIE_API_CLIENT_SECRET

export const requestOptions = {
  get() {
    return {
      method: 'GET',
      headers: {
        ...headers(),
        'Content-Type': 'application/json',
      },
    }
  },
  post(body) {
    const formBody = new URLSearchParams(body)

    // In the process of creating form parameters, any null or undefined values
    // will be converted to 'null' and 'undefined'. Convert them back to original
    // values to prevent server errors.
    removeNullAndUndefinedValues(formBody)

    return {
      method: 'POST',
      headers: {
        ...headers(),
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: formBody,
    }
  },
  postFileUpload(file) {
    const formData = new FormData()
    formData.append('file', file)

    return {
      method: 'POST',
      headers: {
        ...headers(),
      },
      body: formData,
    }
  },
  patch(body) {
    return {
      method: 'PATCH',
      headers: {
        ...headers(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  },
  put(body) {
    return {
      method: 'PUT',
      headers: {
        ...headers(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  },
  delete() {
    return {
      method: 'DELETE',
      headers: {
        ...headers(),
        'Content-Type': 'application/json',
      },
    }
  },
}

function headers() {
  const localAuthToken = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CURRENT_USER_KEY))?.token
  const authHeader = localAuthToken ? { Authorization: 'Bearer ' + localAuthToken } : {}
  return {
    ...authHeader,
    Accept: 'application/json; version=2',
    'x-client-id': process.env.REACT_APP_PIXIE_API_CLIENT_ID,
  }
}

function removeNullAndUndefinedValues(paramsObject) {
  var itemsToDelete = []

  for (const p of paramsObject) {
    if (p.length !== 2) continue

    if (p[1] === 'undefined' || p[1] === 'null') {
      itemsToDelete.push(p[0])
    }
  }

  for (const item of itemsToDelete) {
    paramsObject.delete(item)
  }
}
