import React from 'react'
import { useHistory } from 'react-router-dom'
import { Label2XLarge, Label3XLarge, Label4XLarge, LabelLarge, LabelMedium, LabelSmall } from '@pixieme/pixie-react-components'
import { useAppSelector } from '../../../redux/Hooks'

const DashboardNewBusiness = () => {
  const { userAccount } = useAppSelector(({ userAccountReducer: reducer }) => reducer)
  const merchantId = userAccount && userAccount.businesses && userAccount.businesses.length > 0 ? userAccount.businesses[0].merchantId : ''

  return (
    <div className="flex flex-col space-y-6 sm:space-y-12 bg-white rounded-md px-4 pt-2 pb-6 md:p-8 shadow-sm">
      <ReadyToGoSection merchantId={merchantId} />
      <DownloadPixieAppSection />
      <PixieTerminalSection />
    </div>
  )
}

export default DashboardNewBusiness

const ReadyToGoSection = ({ merchantId }: ReadyToGoSectionProps) => {
  const history = useHistory()

  return (
    <div className="flex flex-col items-start space-y-4">
      <Label4XLarge variant="dark" label="You're ready to go!" additionalCss="font-extrabold tracking-tight hidden sm:flex" />
      <Label3XLarge variant="dark" label="You're ready to go!" additionalCss="font-extrabold tracking-tight sm:hidden" />
      <div className="flex space-x-1 items-center">
        <LabelLarge variant="dark" label="Your Merchant ID:" additionalCss="hidden sm:flex" />
        <LabelMedium variant="dark" label="Your Merchant ID:" additionalCss="sm:hidden" />
        <LabelLarge variant="dark" label={merchantId} additionalCss="font-extrabold" />
      </div>
      <div className="">
        <span className="text-sm text-dark">
          You&apos;ll be asked for this merchant ID when you set up a new payment terminal. If you ever need to set up a payment terminal again, you can find
          this ID on the
          <button type="button" className="text-primary text-sm ml-1 underline" onClick={() => history.push('/settings/merchant-id')}>
            settings page.
          </button>
        </span>
      </div>
    </div>
  )
}

const DownloadPixieAppSection = () => (
  <div className="flex flex-col items-start space-y-4">
    <Label3XLarge variant="dark" label="Download the Pixie Go App" additionalCss="font-extrabold tracking-tight hidden sm:flex" />
    <Label2XLarge variant="dark" label="Download the Pixie Go App" additionalCss="font-extrabold tracking-tight sm:hidden" />
    <div className="flex">
      <div className="flex flex-col items-start space-y-4">
        <LabelSmall
          variant="dark"
          label="You can start taking payments immediately by downloading the Pixie Go app from the Apple App Store or the Google Play Store."
        />
        <LabelSmall variant="dark" label="Scan the QR code to download the Pixie Go app for your device, or use the links below." />
        <div className="hidden md:block mt-4">
          <AppStoreLogos />
        </div>
      </div>
      <img src="img/apps-qr-code.png" className="ml-4 w-20 h-20 sm:w-32 sm:h-32" alt="Pixie QR Code" />
    </div>
    <div className="visible md:hidden">
      <AppStoreLogos />
    </div>
  </div>
)

const AppStoreLogos = () => (
  <div className="flex space-x-4">
    <div>
      <a href="https://apps.apple.com/gb/app/pixie-go/id1173967593">
        <img src="img/app-store-logo.svg" alt="Apple App Store" />
      </a>
    </div>
    <div>
      <a href="https://play.google.com/store/apps/details?id=com.cube.pixie.merchant">
        <img src="img/play-store-logo.svg" alt="Google Plat Store" />
      </a>
    </div>
  </div>
)

const PixieTerminalSection = () => (
  <div className="flex flex-col items-start space-y-4">
    <Label3XLarge variant="dark" label="The Pixie Terminal" additionalCss="font-extrabold tracking-tight hidden sm:flex" />
    <Label2XLarge variant="dark" label="The Pixie Terminal" additionalCss="font-extrabold tracking-tight sm:hidden" />
    <div className="flex">
      <LabelSmall
        variant="dark"
        label="We'll deliver a welcome pack to you in the next few days. This includes a Pixie Terminal that you can use to take payments from your counter or anywhere within range of your internet connected wi-fi."
      />
      <img src="img/wisepos.jpeg" className="ml-6 w-20 sm:w-28" alt="WisePos Terminal" />
    </div>
  </div>
)

/* types */
type ReadyToGoSectionProps = {
  merchantId: string
}
