import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Label4XLarge, LabelMedium } from '@pixieme/pixie-react-components'
import { useAppSelector } from '../../../redux/Hooks'

const DashboardSignupProgress = () => (
  <div className="bg-white shadow-sm rounded-md">
    <div className="p-6 sm:p-8">
      <Label4XLarge variant="dark" label="Welcome to Pixie !" additionalCss="font-extrabold tracking-tight" />
      <LabelMedium
        variant="dark"
        label={"You'll need to complete a simple two-part sign-up process to be securely set up on the Pixie Platform."}
        additionalCss="my-4"
      />
      <LabelMedium variant="dark" label={'Tell us about your business so that we can pay you when your customers pay with Pixie.\n'} />
      <LabelMedium
        variant="dark"
        label={'Tell us all about why you do what you do, so we can showcase your business to potential customer using the Pixie app.\n'}
      />
      <div className="mt-6">
        <CtaPanel />
      </div>
    </div>
  </div>
)

export default DashboardSignupProgress

const CtaPanel = () => {
  const { completedSignup } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)
  const { completedDiscovery } = useAppSelector(({ setupDiscoveryReducer: reducer }) => reducer)

  const history = useHistory()

  return (
    <div className="flex flex-col space-y-4 sm:space-y-8">
      <DiscoveryButton
        disabled={completedSignup}
        label="Step 1 - tell us about your business"
        onClick={() => {
          history.push('/setup/account-setup')
        }}
      />
      <DiscoveryButton
        disabled={completedDiscovery}
        label="Step 2 - tell us what you do"
        onClick={() => {
          history.push('/setup/business-profile-setup')
        }}
      />
    </div>
  )
}

type DiscoveryButtonProps = {
  disabled: boolean
  label: string
  onClick?: () => void
}

const DiscoveryButton: FC<DiscoveryButtonProps> = ({ disabled, label, onClick }) => (
  <div>
    <button
      type="button"
      onClick={onClick}
      className={[
        'px-2 sm:px-6 py-2 text-base sm:text-xl border-2 w-full sm:w-auto rounded-full',
        disabled ? 'border-primary/40' : 'border-primary hover:bg-primary hover:bg-opacity-10',
      ].join(' ')}>
      {disabled ? (
        <span className="pr-2">
          <FontAwesomeIcon icon={faCheck} size="sm" className="text-primary/40" />
        </span>
      ) : (
        <></>
      )}
      <span className={['font-extrabold', disabled ? 'text-primary/40' : 'text-primary'].join(' ')}>{label}</span>
    </button>
  </div>
)
