import React from 'react'
import { Label2XLarge, Label4XLarge, LabelSmall } from '@pixieme/pixie-react-components'
import { useAppSelector } from '../../redux/Hooks'

const MerchantIdSettingsPage = () => {
  const { userAccount } = useAppSelector(({ userAccountReducer: reducer }) => reducer)

  const merchantId = userAccount && userAccount.businesses && userAccount.businesses.length > 0 ? userAccount.businesses[0].merchantId : ''

  return (
    <div className="md:max-w-5xl mx-auto px-2">
      <div className="bg-white shadow-sm rounded-md p-6 sm:p-8">
        <Label4XLarge variant="dark" label="Merchant ID" additionalCss="font-extrabold tracking-tight hidden sm:flex" />
        <Label2XLarge variant="dark" label="Merchant ID" additionalCss="font-extrabold tracking-tight sm:hidden" />
        <div className="mt-6 space-y-2">
          <LabelSmall
            variant="dark"
            label={
              "You'll be asked for this merchant ID when you set up a new terminal for your business, and when you run the Pixie Go app for the first time:"
            }
          />
          <Label2XLarge variant="dark" label={merchantId} additionalCss="font-extrabold" />
        </div>
      </div>
    </div>
  )
}

export default MerchantIdSettingsPage
