import { Dispatch } from 'react'
import { AppUser } from '../../../models/models'
import { AppActions } from '../App/AppActionCreators'
import { UserAccountActions } from '../UserAccount/UserAccountActionCreators'
import { UserAccount } from '../UserAccount/UserAccountReducer'
import { CombinedActions } from '../../store'
import { LOCAL_STORAGE_CURRENT_USER_KEY } from './AuthReducer'
import { authenticationService, userAccountService } from '../../../services/pixie'

export type AuthActions =
  | { type: 'loginRequest'; email: string; password: string }
  | { type: 'loginSuccess'; user: AppUser }
  | { type: 'loginFailure'; error: string; description: string }
  | { type: 'logout' }
  | { type: 'resetStoreAuth' }

const LoginAction = (email: string, password: string) => async (dispatch: Dispatch<AuthActions | AppActions | UserAccountActions>) => {
  try {
    dispatch({ type: 'loginRequest', email, password })
    const user = await authenticationService.login(email, password)

    if (user.roles.includes('business')) {
      // TODO: vulnerable to XSS attacks
      localStorage.setItem(LOCAL_STORAGE_CURRENT_USER_KEY, JSON.stringify(user))

      const userAccount: UserAccount = await userAccountService.getAccountDetails()
      dispatch({ type: 'getAccountDetailsSuccess', userAccount })

      dispatch({ type: 'loginSuccess', user })
    } else {
      dispatch({
        type: 'loginFailure',
        error: 'loginFailure',
        description: "There's no business associated with this log-in. Please check that you're logging in with your business account credentials.",
      })
    }

    return user || null
  } catch (error) {
    dispatch({ type: 'loginFailure', error: error?.error, description: error?.error_description ?? 'An error has occurred trying to login !' })
    return null
  }
}

const LogoutAction = () => (dispatch: Dispatch<CombinedActions>) => {
  dispatch({ type: 'logout' })
  dispatch({ type: 'resetStoreApp' })
  dispatch({ type: 'resetStoreAuth' })
  dispatch({ type: 'resetStoreSetupAccount' })
  dispatch({ type: 'resetStoreSetupDiscovery' })
  dispatch({ type: 'resetStoreUserAccount' })
  if (localStorage.getItem(LOCAL_STORAGE_CURRENT_USER_KEY)) {
    localStorage.removeItem(LOCAL_STORAGE_CURRENT_USER_KEY)
  }
}

export { LoginAction, LogoutAction }
