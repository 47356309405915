import React, { useContext, useEffect, useState } from 'react'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import Joi, { ObjectSchema, ValidationErrorItem } from 'joi'
import { DangerInformationCard, InputMedium, LabelExtraSmall, PrimaryButton, SuccessInformationCard } from '@pixieme/pixie-react-components'
import { AuthPageDispatchContext, AuthPageStateContext } from '../AuthPage'
import { useAppSelector } from '../../../../redux/Hooks'
import { ResetPasswordAction } from '../../../../redux/reducers/UserAccount/UserAccountActionCreators'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import ValidationCard from '../../../../appComponents/cards/ValidationCard'

const RecoverPassword = () => {
  const { resetPasswordSuccess, resettingPassword, resetPasswordError } = useAppSelector(({ userAccountReducer: reducer }) => reducer)
  const localDispatch = useContext(AuthPageDispatchContext)
  const localState = useContext(AuthPageStateContext)
  const [formValidationErrors, setFormValidationErrors] = useState<ValidationErrorItem[]>([])
  const dispatch = useContext(AuthPageDispatchContext)
  const appDispatch = useDispatch()

  useEffect(() => {
    appDispatch({ type: 'resetPasswordForm' })
  }, [])

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const validationErrors = ValidateSchema(ValidationSchema(), localState.recoverEmail)

    if (validationErrors.length === 0) {
      setFormValidationErrors([])
      appDispatch(ResetPasswordAction(localState.recoverEmail))
    } else {
      setFormValidationErrors(validationErrors)
    }
  }

  return (
    <div className="w-full">
      <form>
        <div className="bg-inputBackground py-1 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Email" additionalCss="font-extrabold uppercase" />
          <InputMedium
            inputType="email"
            value={localState.recoverEmail}
            onChange={(e) => localDispatch({ type: 'recoverPasswordChangeEmail', email: e.currentTarget.value })}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.email} />
        </div>
        {resetPasswordSuccess && (
          <div className="my-4">
            <SuccessInformationCard icon={faInfoCircle} iconSize="2x" label="Your password has been reset. Please check your email to set a new password." />
          </div>
        )}
        {resetPasswordError && (
          <div className="my-4">
            <DangerInformationCard icon={faInfoCircle} iconSize="2x" label={resetPasswordError.description} />
          </div>
        )}
        <div className="flex justify-center space-x-4 mt-8">
          <PrimaryButton label="Back" size="small" roundedType="full" onClick={() => dispatch({ type: 'showLogin' })} />
          <BusyButton
            isBusy={resettingPassword}
            label="Reset Password"
            size="small"
            onClick={(e) => validateAndSubmit(e)}
            roundedType="full"
            additionalCss="px-6 hover:bg-red-600"
          />
        </div>
      </form>
    </div>
  )
}

export default RecoverPassword

/* validation logic */
const validationFieldKeys = {
  email: 'email',
}

const ValidationSchema = (): ObjectSchema =>
  Joi.object().keys({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .message('Valid Email is required'),
  })

const ValidateSchema = (schema: ObjectSchema, email: string): ValidationErrorItem[] => {
  const { error } = schema.validate({ email }, { abortEarly: false })
  return error?.details ?? []
}
