import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Disclosure } from '@headlessui/react'
import { LabelMedium, LabelSmall } from '@pixieme/pixie-react-components'

const SetupHeaderCard: FC<SetupHeaderCardProps> = ({ completed, enabled, labelLeft, labelRight, children }) => (
  <Disclosure defaultOpen>
    {({ open }) => (
      <>
        <Disclosure.Button className="w-full">
          <div className="px-4 py-4">
            <div className="flex justify-between items-center">
              <div className="flex space-x-2 items-center">
                <LabelMedium variant="dark" label={labelLeft} additionalCss={['font-extrabold ', enabled ? 'text-opacity-100' : 'text-opacity-30'].join(' ')} />
                {completed && <FontAwesomeIcon icon={faCheckCircle} size="sm" className="text-success" />}
              </div>
              <div className="flex space-x-2 items-center">
                <LabelSmall variant="dark" label={labelRight} additionalCss={['font-semibold ', enabled ? 'text-opacity-100' : 'text-opacity-30'].join(' ')} />
                <FontAwesomeIcon
                  icon={enabled && open ? faChevronUp : faChevronDown}
                  size="xs"
                  className={['text-dark', enabled ? 'text-opacity-100' : 'text-opacity-30'].join(' ')}
                />
              </div>
            </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel static>{enabled && open && children}</Disclosure.Panel>
      </>
    )}
  </Disclosure>
)

export default SetupHeaderCard

/* Types */
type SetupHeaderCardProps = {
  enabled: boolean
  completed: boolean
  labelLeft: string
  labelRight: string
  openState: SetupHeaderCardPropsHeaderOpenState
}

type SetupHeaderCardPropsHeaderOpenState = 'open' | 'closed'
