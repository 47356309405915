import { useAppSelector } from '../../Hooks'
import { SetupDiscoverySection } from './SetupDiscoveryReducer'
import { TradingType } from '../../../models/models'

const NextActiveDiscoverySetupSection = (): SetupDiscoverySection | null => {
  const { completedSignup } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)
  const { completedDiscovery, completedBasicsSection, completedClassificationSection, completedPhotosSection, completedCopySection, businessTradingType } = useAppSelector(
    ({ setupDiscoveryReducer: reducer }) => reducer
  )

  if (completedDiscovery) {
    return null
  }

  if (completedSignup &&
      !completedBasicsSection) {
    return 'basics'
  }
  if (completedSignup &&
      completedBasicsSection &&
      !completedClassificationSection) {
    return 'classification'
  }
  if (completedSignup &&
      businessTradingType === TradingType.BricksAndMortar &&
      completedBasicsSection &&
      completedClassificationSection &&
      !completedPhotosSection) {
    return 'photos'
  }
  if (completedSignup &&
      businessTradingType === TradingType.BricksAndMortar &&
      completedBasicsSection &&
      completedClassificationSection &&
      completedPhotosSection &&
      !completedCopySection) {
    return 'copy'
  }

  return null
}

export default NextActiveDiscoverySetupSection
