import React, { FC, Fragment, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Listbox, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown, faChevronUp, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Joi, { ObjectSchema, ValidationErrorItem } from 'joi'
import { DangerInformationCard, LabelExtraSmall, LabelSmall, TagItem, TagsComponent } from '@pixieme/pixie-react-components'
import { AddClassificationAction, GetBusinessCategoriesAction } from '../../../../redux/reducers/SetupDiscovery/SetupDiscoveryActionCreators'
import { BusinessCategory } from '../../../../services/pixie/Models/ApiModels'
import { useAppSelector } from '../../../../redux/Hooks'
import { DiscoverySetupDispatchContext, DiscoverySetupStateContext } from '../index'
import SetupHeaderCard from '../../../../appComponents/SetupHeader/SetupHeaderCard'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import ValidationCard from '../../../../appComponents/cards/ValidationCard'
import AppConfig from '../../../../config/appConfig'

const DiscoveryDetailsClassification = React.forwardRef<HTMLDivElement, DiscoveryDetailsClassificationProps>(({ enabled, completed }, ref) => (
  <div ref={ref} className="bg-white w-full shadow-sm rounded-md" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
    <SetupHeaderCard completed={completed} enabled={enabled} labelLeft="Classification" labelRight="Step 2 of 4" openState="closed">
      <DiscoveryDetailsClassificationForm />
    </SetupHeaderCard>
  </div>
))

export default DiscoveryDetailsClassification

const DiscoveryDetailsClassificationForm = () => {
  const {
    businessCategories,
    status: { addingClassification },
  } = useAppSelector(({ setupDiscoveryReducer: reducer }) => reducer)
  const [formValidationErrors, setFormValidationErrors] = useState<ValidationErrorItem[]>([])
  const [secondaryCategories, setSecondaryCategories] = useState<BusinessCategory[]>([])
  const [tertiaryCategories, setTertiaryCategories] = useState<BusinessCategory[]>([])
  const [primaryCategory, setPrimaryCategory] = useState<BusinessCategory | null>(null)
  const [secondaryCategory, setSecondaryCategory] = useState<BusinessCategory | null>(null)
  const [tertiaryCategory, setTertiaryCategory] = useState<BusinessCategory | null>(null)

  const localState = useContext(DiscoverySetupStateContext)
  const localDispatch = useContext(DiscoverySetupDispatchContext)

  const appDispatch = useDispatch()

  useEffect(() => {
    appDispatch(GetBusinessCategoriesAction())
  }, [])

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const validationErrors = ValidateSchema(ValidationSchema(), primaryCategory?.name ?? '', localState.discoveryClassification.Keywords)

    if (validationErrors.length === 0) {
      setFormValidationErrors([])
      appDispatch(AddClassificationAction(localState.discoveryClassification))
    } else {
      setFormValidationErrors(validationErrors)
    }
  }

  const onTagsChanged = (updatedTags: TagItem[]) => {
    localDispatch({
      type: 'discoveryClassificationChanged',
      discoveryClassification: {
        ...localState.discoveryClassification,
        Keywords: updatedTags?.map((item) => item.name),
      },
    })
  }

  const updateState = () => {
    localDispatch({
      type: 'discoveryClassificationChanged',
      discoveryClassification: {
        ...localState.discoveryClassification,
        PrimaryCategoryId: primaryCategory === null ? '' : primaryCategory?.id,
        SecondaryCategoryId: secondaryCategory === null ? '' : secondaryCategory?.id,
        TertiaryCategoryId: tertiaryCategory === null ? '' : tertiaryCategory?.id,
      },
    })
  }

  useEffect(() => {
    setSecondaryCategories([])
    setTertiaryCategories([])
    setPrimaryCategory(null)
    setSecondaryCategory(null)
    setTertiaryCategory(null)
  }, [businessCategories])

  useEffect(() => {
    if (primaryCategory) {
      setSecondaryCategories(businessCategories.filter((x) => x.id !== primaryCategory?.id))
    } else {
      setSecondaryCategories([])
    }
    setSecondaryCategory(null)
    setTertiaryCategory(null)
    setTertiaryCategories([])
    updateState()
  }, [primaryCategory])

  useEffect(() => {
    if (primaryCategory && secondaryCategory) {
      setTertiaryCategories(businessCategories.filter((x) => x.id !== primaryCategory?.id && x.id !== secondaryCategory?.id))
    } else {
      setTertiaryCategories([])
    }
    setTertiaryCategory(null)
    updateState()
  }, [secondaryCategory])

  useEffect(() => {
    updateState()
  }, [tertiaryCategory])

  const onCategorySelected = (categoryType: CategoryType, category: BusinessCategory | null) => {
    switch (categoryType) {
      case 'primary':
        setPrimaryCategory(category)
        break
      case 'secondary':
        setSecondaryCategory(category)
        break
      case 'tertiary':
        setTertiaryCategory(category)
        break
      default:
    }
  }

  const onCategoryDeleted = (categoryType: CategoryType) => {
    switch (categoryType) {
      case 'primary':
        setPrimaryCategory(null)
        break
      case 'secondary':
        setSecondaryCategory(null)
        break
      case 'tertiary':
        setTertiaryCategory(null)
        break
      default:
    }
  }

  return (
    <div className="px-4 pb-6">
      <form>
        <LabelSmall variant="dark" label="Classifying your business helps customers to find it in the Pixie app" />
        <LabelSmall variant="dark" label="Categories" additionalCss="uppercase font-extrabold mt-6 mb-2" />
        <LabelSmall
          variant="dark"
          label="You can choose between one and three categories for your business. These categories appear in the search page of the Pixie app."
        />
        <div className="bg-inputBackground py-1 mt-6 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Primary Category" additionalCss="font-extrabold uppercase" />
          <div className="w-full border-0 border-b border-gray-300">
            <BusinessCategoryListBox categoryType="primary" categories={businessCategories} onSelected={onCategorySelected} onDeleted={onCategoryDeleted} />
          </div>
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.primaryCategory} />
        </div>
        <div className="bg-inputBackground py-1 mt-6 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Secondary Category" additionalCss="font-extrabold uppercase" />
          <div className="w-full border-0 border-b border-gray-300">
            <BusinessCategoryListBox categoryType="secondary" categories={secondaryCategories} onSelected={onCategorySelected} onDeleted={onCategoryDeleted} />
          </div>
        </div>
        <div className="bg-inputBackground py-1 mt-6 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Tertiary Category" additionalCss="font-extrabold uppercase" />
          <div className="w-full border-0 border-b border-gray-300">
            <BusinessCategoryListBox categoryType="tertiary" categories={tertiaryCategories} onSelected={onCategorySelected} onDeleted={onCategoryDeleted} />
          </div>
        </div>
        <LabelSmall variant="dark" label="Keywords" additionalCss="uppercase font-extrabold mt-6 mb-2" />
        <LabelSmall variant="dark" label="Choose some keywords that reflect your business and the products and services you offer." />
        <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
          <TagsComponent
            initialTags={[]}
            placeHolder="Type to add a new keyword"
            onTagsChanged={onTagsChanged}
            styles={{
              containerAdditionalCss: 'py-3',
              inputAdditionalCss: 'focus:outline-none p-2 border text-dark border-bg-gray-400 rounded-md',
              tagBackgroundColor: 'bg-primary',
              tagForegroundColor: 'text-white',
            }}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.keywords} />
        </div>
        {formValidationErrors.length > 0 && (
          <div className="mt-4">
            <DangerInformationCard
              label="There are some issues with the information you've entered above. Please fix these issues and try again."
              icon={faInfoCircle}
              iconSize="lg"
            />
          </div>
        )}
        <BusyButton
          isBusy={addingClassification}
          label="Save and continue"
          size="small"
          roundedType="full"
          additionalCss="mt-6"
          onClick={(e) => validateAndSubmit(e)}
        />
      </form>
    </div>
  )
}

const BusinessCategoryListBox: FC<BusinessCategoryListBoxProps> = ({ categoryType, categories, onSelected, onDeleted }) => {
  const [selectedCategory, setSelectedCategory] = useState<BusinessCategory | null>(null)

  useEffect(() => {
    setSelectedCategory(null)
  }, [categories])

  const onCategorySelected = (category: BusinessCategory) => {
    setSelectedCategory(category)
    onSelected(categoryType, category)
  }

  const onCategoryDeleted = () => {
    setSelectedCategory(null)
    onDeleted(categoryType)
  }

  return (
    <div className="">
      <Listbox value={selectedCategory} onChange={onCategorySelected}>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <div className="flex items-center space-x-2">
                <Listbox.Button className="relative flex-grow text-left h-8">{selectedCategory?.name}</Listbox.Button>
                <button type="button" onClick={() => onCategoryDeleted()} className="flex items-center">
                  <FontAwesomeIcon icon={faTimesCircle} size="xs" className="text-dark/30" />
                </button>
                <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="xs" className="text-dark/30 flex-none" />
              </div>
              <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options className="bg-white border -mx-2 -mb-2 shadow-lg">
                  {categories?.map((category) => (
                    <Listbox.Option key={category.id} value={category} className="bg-white cursor-pointer">
                      <div
                        className={[
                          'flex items-center justify-between py-2 px-2 block hover:bg-red-300',
                          selectedCategory && selectedCategory === category ? 'bg-red-100' : 'bg-white',
                        ].join(' ')}>
                        {category.name}
                        {selectedCategory && selectedCategory === category && <FontAwesomeIcon icon={faCheck} size="xs" className="text-primary" />}
                      </div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

/* Types */
type CategoryType = 'primary' | 'secondary' | 'tertiary'

type BusinessCategoryListBoxProps = {
  categoryType: CategoryType
  categories: BusinessCategory[]
  onSelected: (categoryType: CategoryType, category: BusinessCategory | null) => void
  onDeleted: (categoryType: CategoryType) => void
}

/* types */
type DiscoveryDetailsClassificationProps = {
  enabled: boolean
  completed: boolean
}

/* validation logic */
const validationFieldKeys = {
  primaryCategory: 'primaryCategory',
  keywords: 'keywords',
}

const ValidationSchema = (): ObjectSchema =>
  Joi.object().keys({
    primaryCategory: Joi.string().empty().messages({ 'string.empty': 'Primary category is required' }),
    keywords: Joi.array().items().min(1).messages({ 'array.min': 'Please choose some keywords for your business' }),
  })

const ValidateSchema = (schema: ObjectSchema, primaryCategory: string | null, keywords: string[]): ValidationErrorItem[] => {
  const { error } = schema.validate({ primaryCategory, keywords }, { abortEarly: false })
  return error?.details ?? []
}
