import { UserAccount } from '../redux/reducers/UserAccount/UserAccountReducer'

const businessApprovedWithTransactions = (userAccount: UserAccount | null): boolean =>
  (userAccount && userAccount?.businesses?.length > 0 && userAccount.businesses[0].hasMadeTransactions) ?? false

const businessApprovedWithoutTransactions = (userAccount: UserAccount | null): boolean =>
  (userAccount && userAccount?.businesses?.length > 0 && !userAccount.businesses[0].hasMadeTransactions) ?? false

const businessPendingApproval = (userAccount: UserAccount | null): boolean =>
  (userAccount && userAccount?.pendingBusinesses?.length > 0 && userAccount?.pendingBusinesses[0]?.pendingApproval) ?? false

const businessPending = (userAccount: UserAccount | null): boolean =>
  (userAccount && userAccount?.pendingBusinesses?.length > 0 && !userAccount?.pendingBusinesses[0]?.pendingApproval) ?? false

export { businessApprovedWithTransactions, businessApprovedWithoutTransactions, businessPendingApproval, businessPending }
