import React, { useEffect } from 'react'
import './App.css'
import './assets/css/tailwind.css'
import { Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppMobileMenu, AppSidebar } from './appComponents/layout/app-sidebar/AppSidebar'
import AppTopBar from './appComponents/layout/app-topbar/AppTopbar'
import DashboardPage from './pages/DashboardPage'
import AccountSetupPage from './pages/setup/AccountSetupPage'
import DiscoverySetupPage from './pages/setup/DiscoverySetupPage'
import AuthPage from './pages/system/auth/AuthPage'
import TransactionsPage from './pages/TransactionsPage'
import DiscoverySettingsPage from './pages/settings/DiscoverySettingsPage'
import MerchantIdSettingsPage from './pages/settings/MerchantIdSettingsPage'
import { useAppSelector } from './redux/Hooks'
import { LOCAL_STORAGE_CURRENT_USER_KEY } from './redux/reducers/Authentication/AuthReducer'
import { GetAccountDetailsAction } from './redux/reducers/UserAccount/UserAccountActionCreators'

function App() {
  const { loggedIn } = useAppSelector(({ authReducer: reducer }) => reducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (loggedIn) {
      dispatch(GetAccountDetailsAction())
    }
  }, [])

  return (
    <div>
      {localStorage.getItem(LOCAL_STORAGE_CURRENT_USER_KEY) ? (
        <>
          <Main />
        </>
      ) : (
        <div className="bg-pageBackground">
          <AuthPage />
        </div>
      )}
    </div>
  )
}

export default App

const Main = () => {
  const { isSideMenuOpen, isMobileMenuOpen } = useAppSelector(({ appReducer: reducer }) => reducer)

  return (
    <div>
      <AppTopBar />
      <div className="min-h-screen">
        <div>
          <div className="flex">
            {isSideMenuOpen && <AppSidebar />}
            {isMobileMenuOpen && <AppMobileMenu />}
            <div className={['w-full mt-14 py-6 sm:py-8 overflow-hidden bg-pageBackground', isSideMenuOpen ? 'sm:ml-72' : 'ml-0'].join(' ')}>
              <Switch>
                <Route path="/" exact component={DashboardPage} />
                <Route path="/setup/account-setup" exact component={AccountSetupPage} />
                <Route path="/setup/business-profile-setup" exact component={DiscoverySetupPage} />
                <Route path="/transactions" exact component={TransactionsPage} />
                <Route path="/settings/business-profile" exact component={DiscoverySettingsPage} />
                <Route path="/settings/merchant-id" exact component={MerchantIdSettingsPage} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
