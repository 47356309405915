import produce from 'immer'
import { SetupAccountActions } from './SetupAccountActionCreators'
import { ApiError, BusinessInformation } from '../../../models/models'
import { FormValidationApError } from '../../../services/pixie/Models/ApiModels'

const SetupAccountReducer = (state: SetupAccountState = SetupAccountInitialState, action: SetupAccountActions) => {
  switch (action.type) {
    case 'resetStoreSetupAccount':
      return SetupAccountInitialState
    case 'getSignupStatusRequest':
      return produce(state, (draft) => {
        draft.status.gettingSignupStatus = true
      })
    case 'getSignupStatusSuccess':
      return produce(state, (draft) => {
        draft.completedBusinessInformationSection = action.signupStatus.haveCompletedBusinessInformation
        draft.completedBankDetailsSection = action.signupStatus.haveCompletedBankDetails
        draft.completedSignup = action.signupStatus.haveCompletedSignup
        draft.completedLoyaltyPercentageSection = action.signupStatus.haveCompletedLoyaltyPercent
        draft.completedIdentitySection = action.signupStatus.haveCompletedProofOfIdentity
        draft.status.gettingSignupStatus = false
      })
    case 'getSignupStatusFailure':
      return produce(state, (draft) => {
        draft.status.gettingSignupStatus = false
      })
    case 'resetBusinessInformation':
      return produce(state, (draft) => {
        draft.status.businessInformationError = null
        draft.status.addingBusinessInformation = false
        draft.status.savedBusinessInformation = false
      })
    case 'addBusinessInformationRequest':
      return produce(state, (draft) => {
        draft.status.businessInformation = action.business
        draft.status.addingBusinessInformation = true
      })
    case 'addBusinessInformationSuccess':
      return produce(state, (draft) => {
        draft.status.addingBusinessInformation = false
        draft.status.savedBusinessInformation = true
      })
    case 'addBusinessInformationFailure':
      return produce(state, (draft) => {
        draft.status.businessInformationError = { code: action.error, description: action.description } as ApiError
        draft.status.addingBusinessInformation = false
        draft.status.savedBusinessInformation = false
      })
    case 'addBusinessInformationFormValidationErrors':
      return produce(state, (draft) => {
        draft.status.addingBasicsFormValidationApiErrors = []
        draft.status.addingBasicsFormValidationApiErrors.push(action.error)
        draft.status.addingBusinessInformation = false
        draft.status.savedBusinessInformation = false
      })
    case 'addProofOfIdentityRequest':
      return produce(state, (draft) => {
        draft.status.addingProofOfIdentity = true
      })
    case 'addProofOfIdentitySuccess':
      return produce(state, (draft) => {
        draft.status.addingProofOfIdentity = false
        draft.status.savedProofOfIdentity = true
      })
    case 'addProofOfIdentityFailure':
      return produce(state, (draft) => {
        draft.status.addProofOfIdentityErrors.push(action.error)
        draft.status.addingProofOfIdentity = false
        draft.status.savedProofOfIdentity = false
      })
    case 'addBankDetailsRequest':
      return produce(state, (draft) => {
        draft.status.addingBankDetails = true
      })
    case 'addBankDetailsSuccess':
      return produce(state, (draft) => {
        draft.status.addingBankDetails = false
        draft.status.savedBankDetails = true
      })
    case 'addBankDetailsFailure':
      return produce(state, (draft) => {
        draft.status.addingBankDetails = false
        draft.status.savedBankDetails = false
      })
    case 'resetLoyaltyPercentage': {
      return produce(state, (draft) => {
        draft.status.addingLoyaltyPercentage = false
        draft.status.savedLoyaltyPercentage = false
      })
    }
    case 'addLoyaltyPercentageRequest':
      return produce(state, (draft) => {
        draft.status.addingLoyaltyPercentage = true
      })
    case 'addLoyaltyPercentageSuccess':
      return produce(state, (draft) => {
        draft.status.addingLoyaltyPercentage = false
        draft.status.savedLoyaltyPercentage = true
      })
    case 'addLoyaltyPercentageFailure':
      return produce(state, (draft) => {
        draft.status.addLoyaltyError = { code: action.error, description: action.description } as ApiError
        draft.status.addingLoyaltyPercentage = false
        draft.status.savedLoyaltyPercentage = false
      })
    default:
      return state
  }
}

export type StatusType = {
  businessInformation: BusinessInformation | null
  gettingSignupStatus: boolean
  addingBusinessInformation: boolean
  savedBusinessInformation: boolean
  addingBasicsFormValidationApiErrors: FormValidationApError[]
  addingProofOfIdentity: boolean
  savedProofOfIdentity: boolean
  addingBankDetails: boolean
  savedBankDetails: boolean
  addingLoyaltyPercentage: boolean
  savedLoyaltyPercentage: boolean
  businessInformationError: ApiError | null
  signupStatusError: ApiError | null
  addLoyaltyError: ApiError | null
  addProofOfIdentityErrors: FormValidationApError[]
}

const StatusTypeInitialState: StatusType = {
  businessInformation: null,
  gettingSignupStatus: false,
  addingBusinessInformation: false,
  addingProofOfIdentity: false,
  addingBankDetails: false,
  addingLoyaltyPercentage: false,
  savedBusinessInformation: false,
  savedProofOfIdentity: false,
  savedBankDetails: false,
  savedLoyaltyPercentage: false,
  businessInformationError: null,
  signupStatusError: null,
  addLoyaltyError: null,
  addProofOfIdentityErrors: [],
  addingBasicsFormValidationApiErrors: [],
}

export type SetupAccountState = {
  status: StatusType
  completedBankDetailsSection: boolean
  completedBusinessInformationSection: boolean
  completedIdentitySection: boolean
  completedLoyaltyPercentageSection: boolean
  completedSignup: boolean
}

const SetupAccountInitialState: SetupAccountState = {
  status: StatusTypeInitialState,
  completedBusinessInformationSection: true,
  completedIdentitySection: true,
  completedBankDetailsSection: true,
  completedLoyaltyPercentageSection: true,
  completedSignup: false,
}

export type SetupAccountSection = 'businessInformation' | 'identity' | 'bankDetails' | 'loyaltyPercentage'

export { SetupAccountReducer, SetupAccountInitialState }
