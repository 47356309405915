import React, { FC } from 'react'
import { ValidationErrorItem } from 'joi'

const ValidationCard: FC<ValidationCardProps> = ({ errors, path }) => {
  const fieldErrors = errors.filter((x) => x.path.includes(path))

  if (fieldErrors.length > 0) {
    return (
      <div className="flex flex-col space-y-1 mt-2 py-1 bg-red-400 rounded-sm">
        {fieldErrors.map((error, index) => (
          <div key={index} className="text-white text-sm font-bold px-2">
            *&nbsp;{error.message}
          </div>
        ))}
      </div>
    )
  }
  return <></>
}

export default ValidationCard

/* types */
type ValidationCardProps = {
  errors: ValidationErrorItem[]
  path: string
}
