import React, { FC, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Label3XLarge, LabelExtraSmall, LabelSmall } from '@pixieme/pixie-react-components'
import { formatCurrency } from '../../utils/currency'
import { TransactionElement, TransactionsForDateRange, TransactionsForDateRangeInitialState } from '../../services/pixie/Models/ApiModels'
import { transactionsService } from '../../services/pixie'
import '../../assets/css/date.css'

const TransactionsPage = () => {
  const [startDate, setStartDate] = useState<any>(new Date())
  const [transactions, setTransactions] = useState<TransactionsForDateRange>(TransactionsForDateRangeInitialState)
  const [transactionsAreBeingFetched, setTransactionsAreBeingFetched] = useState<boolean>(false)

  useEffect(() => {
    setTransactionsAreBeingFetched(true)
    transactionsService
      .getTransactions(moment(startDate).format('YYYY-MM-DD'), moment(startDate).add(1, 'days').format('YYYY-MM-DD'))
      .then((response) => {
        setTransactions(response)
      })
      .finally(() => setTransactionsAreBeingFetched(false))
  }, [startDate])

  const DateInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <div className="flex justify-between bg-inputBackground">
      <input
        {...props}
        className="cursor-pointer z-10 bg-transparent focus:outline-none focus:ring-0 focus:border-gray-800  border-0 border-b border-gray-300 w-full px-0 py-0 placeholder-gray-400 -mr-4"
        type="text"
      />
      <FontAwesomeIcon icon={faCalendar} size="1x" color="grey" />
    </div>
  )

  return (
    <div className="md:max-w-5xl mx-auto px-2">
      <div className="bg-white shadow-sm rounded-md">
        <div className="px-2 pt-4 sm:px-8 sm:pt-8">
          <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm w-full sm:w-72">
            <LabelExtraSmall variant="primary" label="Date" additionalCss="font-extrabold uppercase" />
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              showYearDropdown
              dropdownMode="select"
              customInput={React.createElement(React.forwardRef(DateInput))}
            />
          </div>
          <TransactionsPanel startDate={startDate} transactionsForDateRange={transactions} transactionsAreBeingFetched={transactionsAreBeingFetched} />
        </div>
      </div>
    </div>
  )
}

export default TransactionsPage

const TransactionsPanel: FC<TransactionsPanelProps> = ({ startDate, transactionsForDateRange, transactionsAreBeingFetched }) => {
  const currencyCode = () => {
    if (transactionsForDateRange && transactionsForDateRange.transactions && transactionsForDateRange.transactions.length > 0) {
      return transactionsForDateRange.transactions[0].currencyCode
    }
    return 'GBP'
  }

  const totalTransactions = (transactionsForDateRange?.transactions?.length ?? 0).toString()
  const totalSalesFormatted = () => {
    let totalSales = 0
    if (transactionsForDateRange && transactionsForDateRange.transactions && transactionsForDateRange.transactions.length > 0) {
      totalSales = transactionsForDateRange.transactions.reduce((total: number, transaction: TransactionElement) => total + transaction.amount, 0)
    }
    return totalSales
  }

  return (
    <div className="mt-12">
      <TransactionHeaderPanel
        startDate={startDate}
        totalTransactions={totalTransactions}
        totalSalesFormatted={formatCurrency(totalSalesFormatted(), currencyCode())}
        currencyCode={currencyCode()}
        transactionsAreBeingFetched={transactionsAreBeingFetched}
      />
      <TransactionListPanel
        transactions={transactionsForDateRange.transactions}
        currencyCode={currencyCode()}
        transactionsAreBeingFetched={transactionsAreBeingFetched}
      />
    </div>
  )
}

const TransactionHeaderPanel: FC<TransactionHeaderPanelProps> = ({ startDate, totalTransactions, totalSalesFormatted, transactionsAreBeingFetched }) => {
  const selectedDateFormatted = moment(startDate).format('LL')

  return (
    <div>
      <Label3XLarge label={selectedDateFormatted} variant="dark" additionalCss="font-extrabold" />
      <div className="flex justify-between items-center mt-12">
        <div className="flex flex-col">
          {!transactionsAreBeingFetched ? (
            <Label3XLarge variant="dark" label={totalTransactions} additionalCss="font-extrabold" />
          ) : (
            <FontAwesomeIcon icon={faSpinner} size="lg" className="text-gray-400 my-2" spin />
          )}
          <LabelExtraSmall variant="dark" label="Transactions" additionalCss="font-extrabold uppercase" />
        </div>
        <div className="flex flex-col">
          {!transactionsAreBeingFetched ? (
            <Label3XLarge variant="dark" label={totalSalesFormatted} additionalCss="font-extrabold" />
          ) : (
            <FontAwesomeIcon icon={faSpinner} size="lg" className="text-gray-400 my-2" spin />
          )}
          <LabelExtraSmall variant="dark" label="Sales" additionalCss="font-extrabold uppercase text-right" />
        </div>
      </div>
    </div>
  )
}

const TransactionListPanel: FC<TransactionListPanelProps> = ({ currencyCode, transactions, transactionsAreBeingFetched }) => (
  <div className="mt-12">
    <div className="flex justify-between w-full border-b-2 pb-2 border-gray-800">
      <LabelExtraSmall variant="dark" label="Time" additionalCss="font-bold uppercase w-40" />
      <LabelExtraSmall variant="dark" label="Amount" additionalCss="font-bold uppercase w-72 text-right" />
    </div>
    {/* eslint-disable-next-line no-nested-ternary */}
    {!transactionsAreBeingFetched ? (
      transactions && transactions.length > 0 ? (
        <TransactionsList transactions={transactions} currencyCode={currencyCode} />
      ) : (
        <div className="flex items-center justify-center h-48">
          <LabelSmall label="No transactions found for this timeframe" variant="grey" additionalCss="font-medium" />
        </div>
      )
    ) : (
      <div className="flex items-center justify-center h-48">
        <FontAwesomeIcon icon={faSpinner} size="2x" className="text-gray-400" spin />
      </div>
    )}
  </div>
)

const TransactionsList: FC<TransactionsListProps> = ({ transactions, currencyCode }) => (
  <div className="pb-12 divide-y">
    {transactions.map((transaction) => (
      <div className="flex justify-between w-full py-2">
        <LabelSmall variant="dark" label={moment(transaction.timestamp).local().format('HH:mm')} additionalCss="font-medium uppercase w-40" />
        <LabelSmall variant="dark" label={formatCurrency(transaction.amount, currencyCode)} additionalCss="font-medium uppercase w-72 text-right" />
      </div>
    ))}
  </div>
)

/* types */
type TransactionsPanelProps = {
  startDate: Date
  transactionsForDateRange: TransactionsForDateRange
  transactionsAreBeingFetched: boolean
}

type TransactionHeaderPanelProps = {
  startDate: Date
  currencyCode: string
  totalSalesFormatted: string
  totalTransactions: string
  transactionsAreBeingFetched: boolean
}

type TransactionListPanelProps = {
  currencyCode: string
  transactions: TransactionElement[]
  transactionsAreBeingFetched: boolean
}

type TransactionsListProps = {
  currencyCode: string
  transactions: TransactionElement[]
}
