import { apiBaseUrl, apiClientId, apiClientSecret } from '../../utils/requestOptions'
import { handleResponse } from '../../utils/handleResponse'

export const authenticationService = {
  login,
}

function login(username, password) {
  var details = {
    grant_type: 'password',
    client_id: apiClientId,
    client_secret: apiClientSecret,
    username,
    password,
  }

  var formBody = new URLSearchParams(details)

  return fetch(`${apiBaseUrl}/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: formBody,
  })
    .then(handleResponse)
    .then((userDetails) => {
      const user = {
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        email: userDetails?.email,
        token: userDetails?.access_token,
        roles: userDetails?.roles.split(',').map((item) => item.trim().toLowerCase()),
      }

      return user
    })
}
