import React from 'react'
import { useDispatch } from 'react-redux'
import { LabelSmall } from '@pixieme/pixie-react-components'
import SetupHeaderCard from '../../../../appComponents/SetupHeader/SetupHeaderCard'
import { AddPhotosAction } from '../../../../redux/reducers/SetupDiscovery/SetupDiscoveryActionCreators'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import { useAppSelector } from '../../../../redux/Hooks'
import AppConfig from '../../../../config/appConfig'

const DiscoveryDetailsPhotos = React.forwardRef<HTMLDivElement, DiscoveryDetailsPhotosProps>(({ enabled, completed }, ref) => (
  <div ref={ref} className="bg-white w-full shadow-sm rounded-md" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
    <SetupHeaderCard completed={completed} enabled={enabled} labelLeft="Photos" labelRight="Step 3 of 4" openState="closed">
      <DiscoveryDetailsPhotosForm />
    </SetupHeaderCard>
  </div>
))

export default DiscoveryDetailsPhotos

const DiscoveryDetailsPhotosForm = () => {
  const {
    status: { addingPhotos },
  } = useAppSelector(({ setupDiscoveryReducer: reducer }) => reducer)

  const appDispatch = useDispatch()

  return (
    <div className="px-4 pb-6">
      <LabelSmall
        variant="dark"
        label="Your business will be photographed by one of our professional photographers. A member of the Pixie team will be in touch to arrange a photoshoot time to suit you."
      />
      <BusyButton
        buttonType="button"
        isBusy={addingPhotos}
        label="Continue"
        size="small"
        roundedType="full"
        additionalCss="mt-6"
        onClick={() => appDispatch(AddPhotosAction({ nophotos: 'no photos' }))}
      />
    </div>
  )
}

/* types */
type DiscoveryDetailsPhotosProps = {
  enabled: boolean
  completed: boolean
}
