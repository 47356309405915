import { AppActions } from './AppActionCreators'
import { SideMenuItemType } from '../../../appComponents/layout/app-sidebar/NavigationRoutes'

const AppReducer = (state: AppState = AppInitialState, action: AppActions) => {
  switch (action.type) {
    case 'selectSideMenuItem':
      return {
        ...state,
        selectedSideMenuitemType: action.sideMenuItemType,
      }
    case 'resetStoreApp':
      return AppInitialState
    case 'openSideMenu':
      return {
        ...state,
        isSideMenuOpen: true,
      }
    case 'closeSideMenu':
      return {
        ...state,
        isSideMenuOpen: false,
      }
    case 'openMobileMenu':
      return {
        ...state,
        isMobileMenuOpen: true,
      }
    case 'closeMobileMenu':
      return {
        ...state,
        isMobileMenuOpen: false,
      }
    default:
      return state
  }
}

export type AppState = {
  isSideMenuOpen: boolean
  isMobileMenuOpen: boolean
  selectedSideMenuitemType: SideMenuItemType | null
}

const AppInitialState: AppState = {
  isSideMenuOpen: true,
  isMobileMenuOpen: false,
  selectedSideMenuitemType: null,
}

export type businessApprovedStatusType = 'signUpNotStarted' | 'pendingApproval' | 'approved'

export { AppReducer }
