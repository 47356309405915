import { UserAccount } from '../redux/reducers/UserAccount/UserAccountReducer'
import { businessApprovedStatusType } from '../redux/reducers/App/AppReducer'

const UserHasABusinesses = (userAccount: UserAccount): boolean => userAccount && userAccount.businesses && userAccount.businesses.length === 1

const UserHasAPendingBusinesses = (userAccount: UserAccount): boolean =>
  userAccount && userAccount.pendingBusinesses && userAccount.pendingBusinesses.length === 1

const BusinessApprovedStatusType = (userAccount: UserAccount): businessApprovedStatusType => {
  if (UserHasAPendingBusinesses(userAccount)) {
    return 'signUpNotStarted'
  }
  if (UserHasABusinesses(userAccount)) {
    return 'approved'
  }
  return 'signUpNotStarted'
}

const BusinessHasMadeTransactions = (userAccount: UserAccount): boolean => UserHasABusinesses(userAccount) && userAccount.businesses[0].hasMadeTransactions

export { UserHasABusinesses, UserHasAPendingBusinesses, BusinessApprovedStatusType, BusinessHasMadeTransactions }
