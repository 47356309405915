import React, { ChangeEvent, useContext, useState } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Joi, { ObjectSchema, ValidationErrorItem } from 'joi'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { DangerInformationCard, InputSmall, LabelExtraSmall, LabelSmall } from '@pixieme/pixie-react-components'
import { AccountSetupDispatchContext, AccountSetupStateContext } from '../index'
import { AddBusinessInformationAction } from '../../../../redux/reducers/SetupAccount/SetupAccountActionCreators'
import SetupHeaderCard from '../../../../appComponents/SetupHeader/SetupHeaderCard'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import { useAppSelector } from '../../../../redux/Hooks'
import ValidationCard from '../../../../appComponents/cards/ValidationCard'
import '../../../../assets/css/date.css'
import AppConfig from '../../../../config/appConfig'
import { TradingType } from '../../../../models/models'

const AccountDetailsBusinessInformation = React.forwardRef<HTMLDivElement, AccountDetailsBusinessInformationProps>(({ enabled, completed }, ref) => (
  <div ref={ref} className="bg-white w-full shadow-sm rounded-md" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
    <SetupHeaderCard completed={completed} enabled={enabled} labelLeft="Business information" labelRight="Step 1 of 4" openState="closed">
      <AccountDetailsBusinessForm />
    </SetupHeaderCard>
  </div>
))

export default AccountDetailsBusinessInformation

const AccountDetailsBusinessForm = () => {
  const {
    status: { addingBusinessInformation, addingBasicsFormValidationApiErrors },
  } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)
  const [incorporationDate, setIncorporationDate] = useState<any>(new Date())
  const [isRegisteredBusinessAddressDifferent, setIsRegisteredBusinessAddressDifferent] = useState(false)
  const [formValidationErrors, setFormValidationErrors] = useState<ValidationErrorItem[]>([])
  const localState = useContext(AccountSetupStateContext)
  const localDispatch = useContext(AccountSetupDispatchContext)
  const appDispatch = useDispatch()

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const validationErrors: ValidationErrorItem[] = []

    const validationErrorsTradingDetails = ValidateTradingDetailsAndAddressSchema(
      ValidationSchema(),
      localState.business.ConfirmedIndependent,
      localState.business.BusinessName,
      localState.business.TradingAddress.AddressLine1,
      localState.business.TradingAddress.City,
      localState.business.TradingAddress.Postcode
    )
    validationErrors.push(...validationErrorsTradingDetails)

    if (localState.business.IsARegisteredBusiness) {
      const validationErrorsRegisteredDetails = ValidateRegisteredDetailsSchema(
        ValidationSchema(),
        localState.business.IncorporationDate,
        localState.business.RegistrationNumber,
        localState.business.LegalName
      )
      validationErrors.push(...validationErrorsRegisteredDetails)

      if (isRegisteredBusinessAddressDifferent) {
        const validationErrorsRegisteredAddress = ValidateRegisteredAddressSchema(
          ValidationSchema(),
          localState.business.RegisteredAddress.AddressLine1,
          localState.business.RegisteredAddress.City,
          localState.business.RegisteredAddress.Postcode
        )
        validationErrors.push(...validationErrorsRegisteredAddress)
      }
    }

    if (validationErrors.length === 0) {
      setFormValidationErrors([])
      appDispatch(AddBusinessInformationAction(localState.business))
    } else {
      setFormValidationErrors(validationErrors)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    localDispatch({ type: 'businessInformationChanged', businessInformation: { ...localState.business, [e.target.name]: value } })
  }

  const handleRegisteredAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    localDispatch({
      type: 'businessInformationChanged',
      businessInformation: { ...localState.business, RegisteredAddress: { ...localState.business.RegisteredAddress, [e.target.name]: value } },
    })
  }

  const handleTradingAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    localDispatch({
      type: 'businessInformationChanged',
      businessInformation: { ...localState.business, TradingAddress: { ...localState.business.TradingAddress, [e.target.name]: value } },
    })
  }

  const handleIncorporationDateChange = (date: any) => {
    setIncorporationDate(date)
    if (date) {
      localDispatch({
        type: 'businessInformationChanged',
        businessInformation: { ...localState.business, IncorporationDate: moment(date).format('YYYY-MM-DD') },
      })
    }
  }

  const DateInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <div className="flex justify-between bg-inputBackground">
      <input
        {...props}
        className="cursor-pointer z-10 bg-transparent focus:outline-none focus:ring-0 focus:border-gray-800  border-0 border-b border-gray-300  w-full px-0 py-0 placeholder-gray-400 -mr-4"
        type="text"
      />
      <FontAwesomeIcon icon={faCalendar} size="1x" color="grey" />
    </div>
  )

  const formattedIncorporationDate = (dateAsString: string) => {
    if (moment(dateAsString).isValid()) {
      return moment(dateAsString).format('YYYY-MM-DD')
    }
    return ''
  }

  return (
    <div className="px-4 pb-6">
      <form>
        <LabelSmall variant="dark" label="To sign up with Pixie you need to confirm that you own an independent business (five retail locations or fewer)." />
        <div className="my-5">
          <div>
            <label htmlFor="ConfirmedIndependent" className="text-dark text-sm flex items-center">
              <input
                type="checkbox"
                id="ConfirmedIndependent"
                checked={localState.business.ConfirmedIndependent}
                name="ConfirmedIndependent"
                className="mr-2 outline-none focus:ring-0 text-primary"
                onChange={(e) => handleChange(e)}
              />
              I confirm that I am registering an independent business.
            </label>
          </div>
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.confirmedIndependent} />
        </div>
        <div className="bg-inputBackground py-1 mt-5 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Company Name" additionalCss="font-extrabold uppercase" />
          <InputSmall
            inputType="text"
            value={localState.business.BusinessName}
            name="BusinessName"
            placeHolder="The trading name of my business"
            onChange={(e) => handleChange(e)}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.businessName} />
        </div>
        <div className="py-1 mt-4">
          <LabelSmall variant="dark" label="Type of trade" additionalCss="mb-2" />
          <div className="my-1">
            <label htmlFor="TradingTypeBricksAndMortar">
              <input
                id="TradingTypeBricksAndMortar"
                type="radio"
                name="TradingType"
                onChange={(e) => handleChange(e)}
                className="mr-2 outline-none focus:ring-0 text-primary"
                value={TradingType.BricksAndMortar}
                checked={localState.business.TradingType === TradingType.BricksAndMortar}
              />
              Bricks and mortar
            </label>
          </div>
          <div className="my-1">
            <label htmlFor="TradingTypeTrader">
              <input
                id="TradingTypeTrader"
                type="radio"
                name="TradingType"
                onChange={(e) => handleChange(e)}
                className="mr-2 outline-none focus:ring-0 text-primary"
                value={TradingType.Trader}
                checked={localState.business.TradingType === TradingType.Trader}
              />
              Trader / Event organiser
            </label>
          </div>
        </div>
        <LabelSmall
          variant="dark"
          label={localState.business.TradingType === TradingType.BricksAndMortar ? "Business Address" : "Contact Address"}
          additionalCss="uppercase font-extrabold mt-6" />
        <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Address Line 1" additionalCss="font-extrabold uppercase" />
          <InputSmall
            inputType="text"
            value={localState.business.TradingAddress.AddressLine1}
            name="AddressLine1"
            placeHolder="Address Line 1"
            onChange={(e) => handleTradingAddressChange(e)}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.tradingAddressLine1} />
        </div>
        <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Address Line 2" additionalCss="font-extrabold uppercase" />
          <InputSmall
            inputType="text"
            value={localState.business.TradingAddress.AddressLine2}
            name="AddressLine2"
            placeHolder="Address Line 2"
            onChange={(e) => handleTradingAddressChange(e)}
          />
        </div>
        <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="City" additionalCss="font-extrabold uppercase" />
          <InputSmall
            inputType="text"
            value={localState.business.TradingAddress.City}
            name="City"
            placeHolder="City"
            onChange={(e) => handleTradingAddressChange(e)}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.tradingAddressCity} />
        </div>
        <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="County" additionalCss="font-extrabold uppercase" />
          <InputSmall
            inputType="text"
            value={localState.business.TradingAddress.County}
            name="County"
            placeHolder="County"
            onChange={(e) => handleTradingAddressChange(e)}
          />
        </div>
        <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Postcode" additionalCss="font-extrabold uppercase" />
          <InputSmall
            inputType="text"
            value={localState.business.TradingAddress.Postcode}
            name="Postcode"
            placeHolder="Postcode"
            onChange={(e) => handleTradingAddressChange(e)}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.tradingAddressPostCode} />
          <ValidationCard
            errors={addingBasicsFormValidationApiErrors.map((item) => ({ message: item.message, path: [item.path] } as ValidationErrorItem))}
            path="tradingPostcode"
          />
        </div>
        <div className="my-4">
          <div className="mt-6">
            <label htmlFor="IsARegisteredBusiness" className="text-dark text-sm flex items-center">
              <input
                id="IsARegisteredBusiness"
                type="checkbox"
                className="mr-2 outline-none focus:ring-0 text-primary"
                name="IsARegisteredBusiness"
                checked={localState.business.IsARegisteredBusiness}
                onChange={(e) => handleChange(e)}
              />
              This is a registered business.
            </label>
          </div>
        </div>
        <Disclosure defaultOpen>
          {({ open }) =>
            localState.business.IsARegisteredBusiness && (
              <Transition
                enter="transition ease-out duration-1000"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-1000"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1">
                <Disclosure.Panel static>
                  <>
                    <LabelSmall variant="dark" label="REGISTERED BUSINESS DETAILS" additionalCss="uppercase font-extrabold mt-6" />
                    <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
                      <LabelExtraSmall variant="primary" label="Incorporation Date" additionalCss="font-extrabold uppercase" />
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        value={formattedIncorporationDate(localState?.business?.IncorporationDate)}
                        onChange={(date) => handleIncorporationDateChange(date)}
                        selectsStart
                        startDate={incorporationDate}
                        showYearDropdown
                        dropdownMode="select"
                        customInput={React.createElement(React.forwardRef(DateInput))}
                      />
                      <ValidationCard errors={formValidationErrors} path={validationFieldKeys.incorporationDate} />
                    </div>
                    <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
                      <LabelExtraSmall variant="primary" label="REGISTRATION NUMBER" additionalCss="font-extrabold uppercase" />
                      <InputSmall
                        value={localState.business.RegistrationNumber}
                        inputType="text"
                        name="RegistrationNumber"
                        placeHolder="12345678"
                        onChange={(e) => handleChange(e)}
                      />
                      <ValidationCard errors={formValidationErrors} path={validationFieldKeys.registrationNumber} />
                    </div>
                    <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
                      <LabelExtraSmall variant="primary" label="LEGAL NAME" additionalCss="font-extrabold uppercase" />
                      <InputSmall
                        value={localState.business.LegalName}
                        inputType="text"
                        name="LegalName"
                        placeHolder="Example Ltd"
                        onChange={(e) => handleChange(e)}
                      />
                      <ValidationCard errors={formValidationErrors} path={validationFieldKeys.legalName} />
                    </div>
                    <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
                      <LabelExtraSmall variant="primary" label="VAT NUMBER" additionalCss="font-extrabold uppercase" />
                      <InputSmall
                        value={localState.business.VatNumber}
                        inputType="text"
                        name="VatNumber"
                        placeHolder="12345678"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="my-4">
                      <div className="mt-6">
                        <label htmlFor="isRegisteredBusinessAddressDifferent" className="text-dark text-sm flex items-center">
                          <input
                            id="isRegisteredBusinessAddressDifferent"
                            type="checkbox"
                            className="mr-2 outline-none focus:ring-0 text-primary"
                            checked={isRegisteredBusinessAddressDifferent}
                            onChange={(e) => setIsRegisteredBusinessAddressDifferent(e.currentTarget.checked)}
                          />
                          The registered address of the business is different from the {localState.business.TradingType === TradingType.BricksAndMortar ? "trading" : "contact"} address.
                        </label>
                      </div>
                    </div>
                  </>
                </Disclosure.Panel>
              </Transition>
            )
          }
        </Disclosure>
        <Disclosure defaultOpen>
          {({ open }) =>
            isRegisteredBusinessAddressDifferent && (
              <Transition
                enter="transition ease-out duration-1000"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-1000"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1">
                <Disclosure.Panel static>
                  <>
                    <LabelSmall variant="dark" label="REGISTERED BUSINESS ADDRESS" additionalCss="uppercase font-extrabold mt-6" />
                    <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
                      <LabelExtraSmall variant="primary" label="Address Line 1" additionalCss="font-extrabold uppercase" />
                      <InputSmall
                        value={localState.business.RegisteredAddress.AddressLine1}
                        inputType="text"
                        name="AddressLine1"
                        placeHolder="Address Line 1"
                        onChange={(e) => handleRegisteredAddressChange(e)}
                      />
                      <ValidationCard errors={formValidationErrors} path={validationFieldKeys.registeredAddressLine1} />
                    </div>
                    <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
                      <LabelExtraSmall variant="primary" label="Address Line 2" additionalCss="font-extrabold uppercase" />
                      <InputSmall
                        value={localState.business.RegisteredAddress.AddressLine2}
                        inputType="text"
                        name="AddressLine2"
                        placeHolder="Address Line 2"
                        onChange={(e) => handleRegisteredAddressChange(e)}
                      />
                    </div>
                    <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
                      <LabelExtraSmall variant="primary" label="City" additionalCss="font-extrabold uppercase" />
                      <InputSmall
                        value={localState.business.RegisteredAddress.City}
                        inputType="text"
                        name="City"
                        placeHolder="City"
                        onChange={(e) => handleRegisteredAddressChange(e)}
                      />
                      <ValidationCard errors={formValidationErrors} path={validationFieldKeys.registeredAddressCity} />
                    </div>
                    <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
                      <LabelExtraSmall variant="primary" label="County" additionalCss="font-extrabold uppercase" />
                      <InputSmall
                        value={localState.business.RegisteredAddress.County}
                        inputType="text"
                        name="County"
                        placeHolder="County"
                        onChange={(e) => handleRegisteredAddressChange(e)}
                      />
                    </div>
                    <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
                      <LabelExtraSmall variant="primary" label="Postcode" additionalCss="font-extrabold uppercase" />
                      <InputSmall
                        value={localState.business.RegisteredAddress.Postcode}
                        inputType="text"
                        name="Postcode"
                        placeHolder="Postcode"
                        onChange={(e) => handleRegisteredAddressChange(e)}
                      />
                      <ValidationCard errors={formValidationErrors} path={validationFieldKeys.registeredAddressPostCode} />
                      <ValidationCard
                        errors={addingBasicsFormValidationApiErrors.map((item) => ({ message: item.message, path: [item.path] } as ValidationErrorItem))}
                        path="registeredPostcode"
                      />
                    </div>
                  </>
                </Disclosure.Panel>
              </Transition>
            )
          }
        </Disclosure>
        {(addingBasicsFormValidationApiErrors.length > 0 || formValidationErrors.length > 0) && (
          <div className="mt-4">
            <DangerInformationCard
              label="There are some issues with the information you've entered above. Please fix these issues and try again."
              icon={faInfoCircle}
              iconSize="lg"
            />
          </div>
        )}
        <BusyButton
          isBusy={addingBusinessInformation}
          onClick={(e) => validateAndSubmit(e)}
          label="Save and continue"
          size="small"
          roundedType="full"
          additionalCss="mt-4"
        />
      </form>
    </div>
  )
}

/* types */
export type AccountDetailsBusinessInformationProps = {
  enabled: boolean
  completed: boolean
}

/* validation logic */
const validationFieldKeys = {
  confirmedIndependent: 'confirmedIndependent',
  businessName: 'businessName',
  tradingAddressLine1: 'tradingAddressLine1',
  tradingAddressCity: 'tradingAddressCity',
  tradingAddressPostCode: 'tradingAddressPostCode',
  incorporationDate: 'incorporationDate',
  registrationNumber: 'registrationNumber',
  legalName: 'legalName',
  registeredAddressLine1: 'registeredAddressLine1',
  registeredAddressCity: 'registeredAddressCity',
  registeredAddressPostCode: 'registeredAddressPostCode',
}

const ValidationSchema = (): ObjectSchema =>
  Joi.object().keys({
    confirmedIndependent: Joi.boolean().invalid(false).messages({ 'any.invalid': 'You must confirm that this is an independent business' }),
    businessName: Joi.string().empty().messages({ 'string.empty': 'Business Name is required' }),
    tradingAddressLine1: Joi.string().empty().messages({ 'string.empty': 'Address Line 1 is required' }),
    tradingAddressCity: Joi.string().empty().messages({ 'string.empty': 'City is required' }),
    tradingAddressPostCode: Joi.string().empty().messages({ 'string.empty': 'Postcode is required' }),
    incorporationDate: Joi.string().empty().messages({ 'string.empty': 'Incorporation Date is required' }),
    registrationNumber: Joi.string().empty().messages({ 'string.empty': 'Registration Number is required' }),
    legalName: Joi.string().empty().messages({ 'string.empty': 'Legal Name is required' }),
    registeredAddressLine1: Joi.string().empty().messages({ 'string.empty': 'Address Line 1 is required' }),
    registeredAddressCity: Joi.string().empty().messages({ 'string.empty': 'City is required' }),
    registeredAddressPostCode: Joi.string().empty().messages({ 'string.empty': 'Postcode is required' }),
  })

const ValidateTradingDetailsAndAddressSchema = (
  schema: ObjectSchema,
  confirmedIndependent: boolean,
  businessName: string,
  tradingAddressLine1: string,
  tradingAddressCity: string,
  tradingAddressPostCode: string
): ValidationErrorItem[] => {
  const { error } = schema.validate(
    { confirmedIndependent, businessName, tradingAddressLine1, tradingAddressCity, tradingAddressPostCode },
    { abortEarly: false }
  )
  return error?.details ?? []
}

const ValidateRegisteredDetailsSchema = (
  schema: ObjectSchema,
  incorporationDate: string,
  registrationNumber: string,
  legalName: string
): ValidationErrorItem[] => {
  const { error } = schema.validate({ incorporationDate, registrationNumber, legalName }, { abortEarly: false })
  return error?.details ?? []
}

const ValidateRegisteredAddressSchema = (
  schema: ObjectSchema,
  registeredAddressLine1: string,
  registeredAddressCity: string,
  registeredAddressPostCode: string
): ValidationErrorItem[] => {
  const { error } = schema.validate({ registeredAddressLine1, registeredAddressCity, registeredAddressPostCode }, { abortEarly: false })
  return error?.details ?? []
}
