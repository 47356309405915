import { apiBaseUrl, requestOptions } from '../../utils/requestOptions'
import { handleResponse } from '../../utils/handleResponse'

export const pixieService = {
  getNearbyBusinesses,
  getBusinessCategories,
}

async function getNearbyBusinesses(latitude, longitude, count) {
  const response = await fetch(
    `${apiBaseUrl}/businesses?` +
      new URLSearchParams({
        lat: latitude,
        lon: longitude,
        count,
      }),
    requestOptions.get()
  )

  return handleResponse(response)
}

async function getBusinessCategories() {
  const response = await fetch(`${apiBaseUrl}/businesses/categories`, requestOptions.get())

  return handleResponse(response)
}
