import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import Joi, { ObjectSchema, ValidationErrorItem } from 'joi'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { DangerInformationCard, InputMedium, LabelExtraSmall, LabelSmall } from '@pixieme/pixie-react-components'
import { AddBankDetailsAction } from '../../../../redux/reducers/SetupAccount/SetupAccountActionCreators'
import { AccountSetupDispatchContext, AccountSetupStateContext } from '../index'
import SetupHeaderCard from '../../../../appComponents/SetupHeader/SetupHeaderCard'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import { useAppSelector } from '../../../../redux/Hooks'
import ValidationCard from '../../../../appComponents/cards/ValidationCard'
import AppConfig from '../../../../config/appConfig'

const AccountDetailsBankDetails = React.forwardRef<HTMLDivElement, AccountDetailsBankDetailsProps>(({ enabled, completed }, ref) => (
  <div ref={ref} className="bg-white w-full shadow-sm rounded-md" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
    <SetupHeaderCard completed={completed} enabled={enabled} labelLeft="Bank details" labelRight="Step 3 of 4" openState="closed">
      <AccountDetailsBankDetailsForm />
    </SetupHeaderCard>
  </div>
))

export default AccountDetailsBankDetails

const AccountDetailsBankDetailsForm = () => {
  const {
    status: { addingBankDetails },
  } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)
  const [formValidationErrors, setFormValidationErrors] = useState<ValidationErrorItem[]>([])
  const localState = useContext(AccountSetupStateContext)
  const localDispatch = useContext(AccountSetupDispatchContext)
  const dispatch = useDispatch()

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const validationErrors = ValidateSchema(
      ValidationSchema(),
      localState.businessBankDetails?.BankName,
      localState.businessBankDetails?.SortCode,
      localState.businessBankDetails?.AccountNumber
    )

    if (validationErrors.length === 0) {
      setFormValidationErrors([])
      dispatch(AddBankDetailsAction(localState.businessBankDetails!))
    } else {
      setFormValidationErrors(validationErrors)
    }
  }

  return (
    <div className="px-4 pb-6">
      <form>
        <LabelSmall variant="dark" label="Transactions between your customers and your business will be settled to this bank account." />
        <div className="bg-inputBackground py-1 mt-6 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Bank Name" additionalCss="font-extrabold uppercase" />
          <InputMedium
            inputType="text"
            placeHolder="Bank name"
            value={localState.businessBankDetails?.BankName}
            onChange={(e) =>
              localDispatch({ type: 'businessBankDetailsChanged', businessBankDetails: { ...localState.businessBankDetails, BankName: e.target.value } })
            }
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.bankName} />
        </div>
        <div className="bg-inputBackground py-1 mt-8 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Sort Code" additionalCss="font-extrabold uppercase" />
          <InputMedium
            inputType="text"
            placeHolder="11-22-33"
            value={localState.businessBankDetails?.SortCode}
            onChange={(e) =>
              localDispatch({ type: 'businessBankDetailsChanged', businessBankDetails: { ...localState.businessBankDetails, SortCode: e.target.value } })
            }
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.sortCode} />
        </div>
        <div className="bg-inputBackground py-1 mt-8 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Account Number" additionalCss="font-extrabold uppercase" />
          <InputMedium
            inputType="text"
            placeHolder="Account number"
            value={localState.businessBankDetails?.AccountNumber}
            onChange={(e) =>
              localDispatch({ type: 'businessBankDetailsChanged', businessBankDetails: { ...localState.businessBankDetails, AccountNumber: e.target.value } })
            }
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.accountNumber} />
        </div>
        {formValidationErrors.length > 0 && (
          <div className="mt-4">
            <DangerInformationCard
              label="There are some issues with the information you've entered above. Please fix these issues and try again."
              icon={faInfoCircle}
              iconSize="lg"
            />
          </div>
        )}
        <BusyButton
          isBusy={addingBankDetails}
          label="Save and continue"
          size="small"
          roundedType="full"
          additionalCss="mt-6"
          onClick={(e) => validateAndSubmit(e)}
        />
      </form>
    </div>
  )
}

/* types */
type AccountDetailsBankDetailsProps = {
  enabled: boolean
  completed: boolean
}

/* validation logic */
const validationFieldKeys = {
  bankName: 'bankName',
  sortCode: 'sortCode',
  accountNumber: 'accountNumber',
}

const ValidationSchema = (): ObjectSchema =>
  Joi.object().keys({
    bankName: Joi.string().empty().messages({ 'string.empty': 'Bank Name is required' }),
    sortCode: Joi.string().empty().messages({ 'string.empty': 'Sort Code is required' }),
    accountNumber: Joi.string().empty().messages({ 'string.empty': 'Account Number is required' }),
  })

const ValidateSchema = (schema: ObjectSchema, bankName?: string, sortCode?: string, accountNumber?: string): ValidationErrorItem[] => {
  const { error } = schema.validate({ bankName, sortCode, accountNumber }, { abortEarly: false })
  return error?.details ?? []
}
