import { OpeningHourLineModel } from '@pixieme/pixie-react-components'
import {
  DiscoveryBasicsInformation,
  DiscoveryBasicsInformationInitialState,
  DiscoveryClassification,
  DiscoveryClassificationInitialState,
} from '../../../../models/models'

const DiscoverySetupReducer = (state: DiscoverySetupState, action: DiscoverySetupActions) => {
  switch (action.type) {
    case 'discoveryBasicsInformationChanged':
      state.discoveryBasicsInformation = action.discoveryBasicsInformation
      break
    case 'discoveryBasicsTimeInformationChanged':
      ;[1, 2, 3, 4, 5, 6, 7].forEach((dayNumber) => ConvertToDayTimes(dayNumber, state.discoveryBasicsInformation, action.times))
      break
    case 'discoveryClassificationChanged':
      state.discoveryClassification = action.discoveryClassification
      break
    case 'sectionOneCompleted':
      break
    case 'sectionTwoCompleted':
      break
    case 'sectionThreeCompleted':
      break
    case 'sectionFourCompleted':
      break
    default:
      break
  }
}

export type DiscoverySetupState = {
  progressState: StateType
  discoveryBasicsInformation: DiscoveryBasicsInformation
  discoveryClassification: DiscoveryClassification
}

const DiscoverySetupInitialState: DiscoverySetupState = {
  progressState: 'notStarted',
  discoveryBasicsInformation: DiscoveryBasicsInformationInitialState,
  discoveryClassification: DiscoveryClassificationInitialState,
}

type StateType =
  | 'notStarted'
  | 'sectionOneComplete'
  | 'sectionTwoComplete'
  | 'sectionThreeComplete'
  | 'sectionFourComplete'
  | 'completedDiscovery'
  | 'sectionOneReadyForCompletion'
  | 'sectionTwoReadyForCompletion'
  | 'sectionThreeReadyForCompletion'
  | 'sectionFourReadyForCompletion'

export type DiscoverySetupActions =
  | { type: 'sectionOneCompleted' }
  | { type: 'sectionTwoCompleted' }
  | { type: 'sectionThreeCompleted' }
  | { type: 'sectionFourCompleted' }
  | { type: 'discoveryBasicsInformationChanged'; discoveryBasicsInformation: DiscoveryBasicsInformation }
  | { type: 'discoveryBasicsTimeInformationChanged'; times: OpeningHourLineModel[] }
  | { type: 'discoveryClassificationChanged'; discoveryClassification: DiscoveryClassification }

export { DiscoverySetupReducer, DiscoverySetupInitialState }

const ConvertToDayTimes = (dayNumber: number, discoveryBasicsInformation: DiscoveryBasicsInformation, times: OpeningHourLineModel[]) => {
  const updatedDayTimes = times.filter((time) => time.Day === dayNumber)
  const updateTimeAsString = updatedDayTimes.map((item) => `${item.FromTime} - ${item.ToTime}`).join(' ')
  switch (dayNumber) {
    case 1:
      discoveryBasicsInformation.openingHoursMonday = updateTimeAsString
      break
    case 2:
      discoveryBasicsInformation.openingHoursTuesday = updateTimeAsString
      break
    case 3:
      discoveryBasicsInformation.openingHoursWednesday = updateTimeAsString
      break
    case 4:
      discoveryBasicsInformation.openingHoursThursday = updateTimeAsString
      break
    case 5:
      discoveryBasicsInformation.openingHoursFriday = updateTimeAsString
      break
    case 6:
      discoveryBasicsInformation.openingHoursSaturday = updateTimeAsString
      break
    case 7:
      discoveryBasicsInformation.openingHoursSunday = updateTimeAsString
      break
    default:
      break
  }
}
