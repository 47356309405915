import React, { FC, Fragment } from 'react'
import { faBars, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, Transition } from '@headlessui/react'
import { useDispatch } from 'react-redux'
import { LabelSmall } from '@pixieme/pixie-react-components'
import PixieLogo from '../../PixieLogo'
import { useAppSelector } from '../../../redux/Hooks'
import { LOCAL_STORAGE_CURRENT_USER_KEY } from '../../../redux/reducers/Authentication/AuthReducer'
import AppConfig from '../../../config/appConfig'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const AppTopBar = () => (
  <div
    className="fixed top-0 index-x-0 z-50 flex justify-between items-center border-b border-gray-200 py-2 bg-white shadow-md w-full px-4"
    style={{ height: AppConfig.navBarHeight }}>
    <OptionsMenu />
    <LogoutMenu />
  </div>
)

export default AppTopBar

const OptionsMenu = () => {
  const { isSideMenuOpen, isMobileMenuOpen } = useAppSelector(({ appReducer: reducer }) => reducer)
  const appDispatch = useDispatch()

  return (
    <>
      <div className="flex space-x-6 sm:hidden">
        <button type="button" onClick={() => appDispatch({ type: isMobileMenuOpen ? 'closeMobileMenu' : 'openMobileMenu' })} className="sm:hidden">
          <FontAwesomeIcon icon={faBars} size="lg" className="text-gray-300" />
        </button>
        <PixieLogo size="xsmall" />
      </div>
      <div className="flex space-x-6 hidden sm:flex">
        <button type="button" onClick={() => appDispatch({ type: isSideMenuOpen ? 'closeSideMenu' : 'openSideMenu' })}>
          {isSideMenuOpen ? <CloseMenuIcon /> : <OpenMenuIcon />}
        </button>
        <PixieLogo size="xsmall" />
      </div>
    </>
  )
}

const OpenMenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="va-icon-menu-collapsed app-navbar__menu">
    <g fill="none" fillRule="nonzero">
      <path d="M0 0h24v24H0z" />
      <rect width="20" height="2" x="2" y="3" fill="#8396a5" rx="1" />
      <path
        fill="#8396a5"
        d="M3 11h10a1 1 0 0 1 0 2H3a1 1 0 0 1 0-2zM20.993 11l-2.7-2.7-1.414 1.414L18.164 11H16a1 1 0 0 0 0 2h2.179l-1.3 1.3 1.414 1.414L21.007 13A1 1 0 0 0 21 11h-.007z"
      />
      <rect width="20" height="2" x="2" y="19" fill="#8396a5" rx="1" />
    </g>
  </svg>
)

const CloseMenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 24 18" className="va-icon-menu app-navbar__menu">
    <g fill="none" fillRule="nonzero" transform="translate(1 -3)">
      <path d="M0 0h24v24H0z" />
      <rect width="20" height="2" x="2" y="3" fill="#8396a5" rx="1" />
      <path fill="#8396a5" d="M11 11h10a1 1 0 0 1 0 2H11a1 1 0 0 1 0-2zM1 11h5a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z" />
      <rect width="20" height="2" x="2" y="19" fill="#8396a5" rx="1" />
      <path stroke="#8396a5" strokeWidth="2" d="M4 9l-3 3 3 3" />
    </g>
  </svg>
)

const LogoutMenu = () => {
  const { userAccount } = useAppSelector(({ userAccountReducer: reducer }) => reducer)
  const businessName = userAccount && userAccount.businesses && userAccount.businesses.length > 0 ? userAccount.businesses[0].name : ''

  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <Menu.Button className="focus:outline-none">
            <div className="flex items-center space-x-2">
              <LabelSmall variant="primary" label={businessName} />
              <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="xs" className="text-primary" />
            </div>
          </Menu.Button>
          <LogoutMenuItems open={open} />
        </>
      )}
    </Menu>
  )
}

const LogoutMenuItems: FC<{ open: boolean }> = ({ open }) => (
  <Transition
    show={open}
    as={Fragment}
    enter="transition ease-out duration-200"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-75"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95">
    <Menu.Items
      static
      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
      <Menu.Item>
        {({ active }) => (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              localStorage.removeItem(LOCAL_STORAGE_CURRENT_USER_KEY)
              window.location.replace('/')
            }}
            className={classNames(active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700')}>
            Logout
          </button>
        )}
      </Menu.Item>
    </Menu.Items>
  </Transition>
)
