import { BusinessBankDetails, BusinessBankDetailsInitialState, BusinessInformation, BusinessInformationInitialState } from '../../../../models/models'

const AccountSetupReducer = (state: AccountSetupState, action: AccountSetupActions) => {
  switch (action.type) {
    case 'businessInformationChanged':
      state.business = action.businessInformation
      break
    case 'businessBankDetailsChanged':
      state.businessBankDetails = action.businessBankDetails
      break
    case 'loyaltyPercentChanged':
      state.loyaltyPercent = action.loyaltyPercent
      break
    case 'sectionOneCompleted':
      break
    case 'sectionTwoCompleted':
      break
    case 'sectionThreeCompleted':
      break
    case 'sectionFourCompleted':
      break
    default:
  }
}

export type AccountSetupState = {
  business: BusinessInformation
  progressState: StateType
  businessBankDetails: BusinessBankDetails | null
  loyaltyPercent: string | null
}

const AccountSetupInitialState: AccountSetupState = {
  business: BusinessInformationInitialState,
  progressState: 'notStarted',
  businessBankDetails: BusinessBankDetailsInitialState,
  loyaltyPercent: null,
}

type StateType =
  | 'notStarted'
  | 'sectionOneComplete'
  | 'sectionTwoComplete'
  | 'sectionThreeComplete'
  | 'sectionFourComplete'
  | 'completedSignup'
  | 'sectionOneReadyForCompletion'
  | 'sectionTwoReadyForCompletion'
  | 'sectionThreeReadyForCompletion'
  | 'sectionFourReadyForCompletion'

export type AccountSetupActions =
  | { type: 'sectionOneCompleted' }
  | { type: 'sectionTwoCompleted' }
  | { type: 'sectionThreeCompleted' }
  | { type: 'sectionFourCompleted' }
  | { type: 'businessInformationChanged'; businessInformation: BusinessInformation }
  | { type: 'businessBankDetailsChanged'; businessBankDetails: BusinessBankDetails }
  | { type: 'loyaltyPercentChanged'; loyaltyPercent: string }

export { AccountSetupReducer, AccountSetupInitialState }
