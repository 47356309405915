import React, { FC, useEffect, useState } from 'react'
import { faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { Label2XLarge, Label3XLarge, LabelLarge, LabelMedium, LabelSmall, OpeningHoursDisplay, TransparentButton } from '@pixieme/pixie-react-components'
import { useDispatch } from 'react-redux'
import { dashboardService } from '../../../services/pixie'
import { DashboardBusinessOverViewData, DashboardBusinessOverViewDataInitialState, TransactionElement } from '../../../services/pixie/Models/ApiModels'
import { formatCurrency } from '../../../utils/currency'
import Mappers from '../../../utils/ApiMappers'

const DashboardBusinessOverview = () => {
  const [dashboardDataIsBeingFetched, setDashboardDataIsBeingFetched] = useState<boolean>(true)
  const [dashboardBusinessOverViewData, setDashboardBusinessOverViewData] = useState<DashboardBusinessOverViewData>(DashboardBusinessOverViewDataInitialState)

  useEffect(() => {
    dashboardService
      .getDashboardData()
      .then((response) => {
        setDashboardBusinessOverViewData(response)
      })
      .finally(() => setDashboardDataIsBeingFetched(false))
  }, [])

  const currencyCode = () => {
    if (dashboardBusinessOverViewData.recentTransactions.length > 0) {
      return dashboardBusinessOverViewData.recentTransactions[0].currencyCode
    }
    return 'GBP'
  }

  return (
    <div>
      <Label3XLarge variant="dark" label="Welcome back. Here’s your business at a glance." additionalCss="font-extrabold tracking-tight hidden sm:flex" />
      <Label2XLarge variant="dark" label="Welcome back. Here’s your business at a glance." additionalCss="font-extrabold tracking-tight sm:hidden" />
      <div className="mt-6 sm:mt-12">
        <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-6">
          <div className="w-full md:w-1/2">
            <TodaySales
              takingsTodayPence={dashboardBusinessOverViewData.takingsTodayPence}
              currencyCode={currencyCode()}
              dashboardDataIsBeingFetched={dashboardDataIsBeingFetched}
            />
            <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row mt-6 md:space-x-6">
              <Loyalty label={`${dashboardBusinessOverViewData.loyaltyPercent.toString()}%`} dashboardDataIsBeingFetched={dashboardDataIsBeingFetched} />
              <Followers label={dashboardBusinessOverViewData.loves.toString()} dashboardDataIsBeingFetched={dashboardDataIsBeingFetched} />
            </div>
          </div>
          <div className="w-full md:w-1/2 flex flex-col">
            <RecentTransactions
              label="No sales yet today"
              recentTransactions={dashboardBusinessOverViewData.recentTransactions}
              currencyCode={currencyCode()}
              dashboardDataIsBeingFetched={dashboardDataIsBeingFetched}
            />
          </div>
        </div>
      </div>
      <div className="flex mt-6">
        <OpeningHours label="" dashboardBusinessOverViewData={dashboardBusinessOverViewData} dashboardDataIsBeingFetched={dashboardDataIsBeingFetched} />
      </div>
    </div>
  )
}

export default DashboardBusinessOverview

const TodaySales: FC<TodaySalesProps> = ({ takingsTodayPence, currencyCode, dashboardDataIsBeingFetched }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const todaysTakingsFormatted = () => {
    if (takingsTodayPence === 0) {
      return 'No sales yet today'
    }
    return formatCurrency(takingsTodayPence, currencyCode)
  }

  return (
    <DashboardContainer headerLabel="Today's Sales">
      <DashboardContainerCta
        label={todaysTakingsFormatted()}
        onClick={() => {
          dispatch({ type: 'selectSideMenuItem', sideMenuItemType: 'transactions' })
          history.push('/transactions')
        }}
        dashboardDataIsBeingFetched={dashboardDataIsBeingFetched}
      />
    </DashboardContainer>
  )
}

const RecentTransactions: FC<RecentTransactionsProps> = ({ label, recentTransactions, currencyCode, dashboardDataIsBeingFetched }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <DashboardContainer headerLabel="Recent Transactions">
      {recentTransactions.length !== 0 ? (
        <div className="overflow-hidden overflow-y-auto h-40">
          <TransactionsList transactions={recentTransactions} currencyCode={currencyCode} />
        </div>
      ) : (
        <DashboardContainerCta
          label={label}
          onClick={() => {
            dispatch({ type: 'selectSideMenuItem', sideMenuItemType: 'transactions' })
            history.push('/transactions')
          }}
          dashboardDataIsBeingFetched={dashboardDataIsBeingFetched}
        />
      )}
    </DashboardContainer>
  )
}

const TransactionsList: FC<TransactionsListProps> = ({ transactions, currencyCode }) => (
  <div className="divide-y">
    {transactions.map((transaction) => (
      <div className="flex justify-between py-2">
        <LabelSmall variant="dark" label={moment(transaction.timestamp).local().format('HH:mm')} additionalCss="font-medium uppercase" />
        <LabelSmall variant="dark" label={formatCurrency(transaction.amount, currencyCode)} additionalCss="uppercase font-bold text-right" />
      </div>
    ))}
  </div>
)

const Loyalty: FC<LoyaltyProps> = ({ label, dashboardDataIsBeingFetched }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <DashboardContainer headerLabel="Loyalty">
      <DashboardContainerCta
        label={label}
        onClick={() => {
          dispatch({ type: 'selectSideMenuItem', sideMenuItemType: 'settingsLoyalty' })
          history.push('/settings/loyalty')
        }}
        dashboardDataIsBeingFetched={dashboardDataIsBeingFetched}
      />
    </DashboardContainer>
  )
}

const Followers: FC<FollowersProps> = ({ label, dashboardDataIsBeingFetched }) => (
  <DashboardContainer headerLabel="Followers">
    {!dashboardDataIsBeingFetched ? (
      <Label2XLarge variant="dark" label={label} additionalCss="font-extrabold" />
    ) : (
      <FontAwesomeIcon icon={faSpinner} size="1x" color="text-gray-300" spin />
    )}
  </DashboardContainer>
)

const OpeningHours: FC<OpeningHoursProps> = ({ label, dashboardBusinessOverViewData, dashboardDataIsBeingFetched }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const openingHours = new Mappers().MapOpeningHoursResponseToOpeningHours(dashboardBusinessOverViewData)

  return (
    <DashboardContainer headerLabel="Opening Hours">
      <Label2XLarge variant="dark" label={label} additionalCss="font-extrabold" />
      {!dashboardDataIsBeingFetched ? (
        <div className="py-6">
          <OpeningHoursDisplay times={openingHours} />
        </div>
      ) : (
        <div className="pb-6">
          <FontAwesomeIcon icon={faSpinner} size="1x" color="text-gray-300" spin />
        </div>
      )}
      <TransparentButton
        label="Adjust your opening hours"
        size="medium"
        roundedType="full"
        additionalCss="border-2 border-primary text-primary"
        onClick={() => {
          dispatch({ type: 'selectSideMenuItem', sideMenuItemType: 'settingsBusinessprofile' })
          history.push('/settings/business-profile')
        }}
      />
    </DashboardContainer>
  )
}

const DashboardContainer: FC<DashboardContainerProps> = ({ headerLabel, children }) => (
  <div className="flex flex-col border-t-8 border-primary rounded-md overflow-hidden bg-white p-4 sm:p-6 flex-grow">
    <LabelLarge variant="primary" label={headerLabel} additionalCss="font-bold uppercase hidden sm:flex" />
    <LabelMedium variant="primary" label={headerLabel} additionalCss="font-bold uppercase sm:hidden" />
    {children}
  </div>
)

const DashboardContainerCta: FC<DashboardContainerCtaProps> = ({ label, onClick, dashboardDataIsBeingFetched }) => (
  <>
    {!dashboardDataIsBeingFetched ? (
      <button type="button" onClick={onClick}>
        <div className="flex items-center space-x-4">
          <Label2XLarge variant="dark" label={label} additionalCss="font-extrabold" />
          <FontAwesomeIcon icon={faChevronRight} size="lg" className="text-gray-300" />
        </div>
      </button>
    ) : (
      <FontAwesomeIcon icon={faSpinner} size="1x" color="text-gray-300" spin />
    )}
  </>
)

/* types */
type DashboardContainerProps = {
  headerLabel: string
}

type DashboardContainerCtaProps = {
  label: string
  onClick?: () => void
  dashboardDataIsBeingFetched: boolean
}

type RecentTransactionsProps = {
  label: string
  recentTransactions: any[]
  currencyCode: string
  dashboardDataIsBeingFetched: boolean
}

type TodaySalesProps = {
  takingsTodayPence: number
  currencyCode: string
  dashboardDataIsBeingFetched: boolean
}

type LoyaltyProps = {
  label: string
  dashboardDataIsBeingFetched: boolean
}

type FollowersProps = {
  label: string
  dashboardDataIsBeingFetched: boolean
}

type OpeningHoursProps = {
  label: string
  dashboardBusinessOverViewData: DashboardBusinessOverViewData
  dashboardDataIsBeingFetched: boolean
}

type TransactionsListProps = {
  currencyCode: string
  transactions: TransactionElement[]
}
