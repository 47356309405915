import React from 'react'
import { useDispatch } from 'react-redux'
import { LabelSmall } from '@pixieme/pixie-react-components'
import { AddLoyaltyPercentageAction } from '../../../../redux/reducers/SetupAccount/SetupAccountActionCreators'
import SetupHeaderCard from '../../../../appComponents/SetupHeader/SetupHeaderCard'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import { useAppSelector } from '../../../../redux/Hooks'
import AppConfig from '../../../../config/appConfig'

const AccountDetailsLoyaltyPercent = React.forwardRef<HTMLDivElement, AccountDetailsLoyaltyPercentProps>(({ enabled, completed }, ref) => (
  <div ref={ref} className="bg-white w-full shadow-sm rounded-md" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
    <SetupHeaderCard completed={completed} enabled={enabled} labelLeft="Loyalty" labelRight="Step 4 of 4" openState="closed">
      <div className="px-4 pb-6 ">
        <AccountDetailsLoyaltyPercentForm />
      </div>
    </SetupHeaderCard>
  </div>
))

export default AccountDetailsLoyaltyPercent

export const AccountDetailsLoyaltyPercentForm = () => {
  const {
    status: { addingLoyaltyPercentage },
  } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)

  const appDispatch = useDispatch()

  return (
    <div className="px-4 pb-6">
      <LabelSmall
        variant="dark"
        label="After you've signed up, you'll be able to choose what kind of loyalty scheme you want to run. You can opt into the Pixie loyalty scheme or create one that's unique for your business. A member of the Pixie team will be in touch to discuss your options."
      />
      <BusyButton
        buttonType="button"
        isBusy={addingLoyaltyPercentage}
        label="Continue"
        size="small"
        roundedType="full"
        onClick={() => appDispatch(AddLoyaltyPercentageAction({ LoyaltyPercent: 2 }))}
        additionalCss="mt-6"
      />
    </div>
  )
}
 


/* types */
type AccountDetailsLoyaltyPercentProps = {
  enabled: boolean
  completed: boolean
}
