/* eslint-disable no-console */
export default class Logger {
  static info = (msg: any) => {
    console.log(`%c ${msg}`, 'background: #0000FF; color: #ffffff')
  }

  static error = (msg: any) => {
    console.log(`%c ${msg}`, 'background: #FF0000; color: #ffffff')
  }

  static success = (msg: any) => {
    console.log(`%c ${msg}`, 'background: #00FF00; color: #000000')
  }
}
