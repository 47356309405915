import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducer from './root-reducer'
import { AppActions } from './reducers/App/AppActionCreators'
import { AuthActions } from './reducers/Authentication/AuthActionCreators'
import { SetupAccountActions } from './reducers/SetupAccount/SetupAccountActionCreators'
import { SetupDiscoveryActions } from './reducers/SetupDiscovery/SetupDiscoveryActionCreators'
import { UserAccountActions } from './reducers/UserAccount/UserAccountActionCreators'

const middlewares = [logger, thunk]

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))

export default store

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type CombinedActions = AppActions | AuthActions | SetupAccountActions | SetupDiscoveryActions | UserAccountActions
