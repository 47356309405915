import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Joi, { ObjectSchema, ValidationErrorItem } from 'joi'
import { DangerInformationCard, InputMedium, LabelExtraSmall, TransparentButton } from '@pixieme/pixie-react-components'
import { AuthPageDispatchContext, AuthPageStateContext } from '../AuthPage'
import { LoginAction, LogoutAction } from '../../../../redux/reducers/Authentication/AuthActionCreators'
import { useAppSelector } from '../../../../redux/Hooks'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import ValidationCard from '../../../../appComponents/cards/ValidationCard'

const Login = () => {
  const { loggingIn, loggingInError } = useAppSelector(({ authReducer: reducer }) => reducer)
  const localDispatch = useContext(AuthPageDispatchContext)
  const localState = useContext(AuthPageStateContext)
  const [formValidationErrors, setFormValidationErrors] = useState<ValidationErrorItem[]>([])
  const LoginDisabled = !(localState.loginEmail && localState.loginPassword)
  const appDispatch = useDispatch()

  useEffect(() => {
    appDispatch(LogoutAction())
  }, [])

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const validationErrors = ValidateSchema(ValidationSchema(), localState.loginEmail, localState.loginPassword)

    if (validationErrors.length === 0) {
      setFormValidationErrors([])
      appDispatch(LoginAction(localState.loginEmail, localState.loginPassword))
    } else {
      setFormValidationErrors(validationErrors)
    }
  }

  return (
    <div className="w-full">
      <form>
        <div className="bg-inputBackground py-1 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Email" additionalCss="font-extrabold uppercase" />
          <InputMedium
            inputType="email"
            value={localState.loginEmail}
            onChange={(e) => localDispatch({ type: 'loginChangeEmail', email: e.currentTarget.value })}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.email} />
        </div>
        <div className="bg-inputBackground py-1 mt-4 px-2 rounded-sm">
          <LabelExtraSmall variant="primary" label="Password" additionalCss="font-extrabold uppercase" />
          <InputMedium
            inputType="password"
            value={localState.loginPassword}
            onChange={(e) => localDispatch({ type: 'loginChangePassword', password: e.currentTarget.value })}
          />
          <ValidationCard errors={formValidationErrors} path={validationFieldKeys.password} />
        </div>
        <div className="my-4">
          <TransparentButton
            label="Recover password"
            size="medium"
            onClick={() => localDispatch({ type: 'showRecoverPassword' })}
            additionalCss="px-0 py-0 font-medium"
            additionalLabelCss="text-primary"
          />
        </div>
        {loggingInError && (
          <div className="my-4">
            <DangerInformationCard icon={faInfoCircle} iconSize="2x" label={loggingInError.description} />
          </div>
        )}
        <div className="flex justify-center mt-8">
          <BusyButton
            isBusy={loggingIn}
            disabled={LoginDisabled}
            label="Login"
            size="small"
            onClick={(e) => validateAndSubmit(e)}
            roundedType="full"
            additionalCss="px-6 hover:bg-red-600"
          />
        </div>
      </form>
    </div>
  )
}

export default Login

/* validation logic */
const validationFieldKeys = {
  email: 'email',
  password: 'password',
}

const ValidationSchema = (): ObjectSchema =>
  Joi.object().keys({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .message('Valid Email is required')
      .required(),
    password: Joi.string().required(),
  })

const ValidateSchema = (schema: ObjectSchema, email: string, password: string): ValidationErrorItem[] => {
  const { error } = schema.validate({ email, password }, { abortEarly: false })
  return error?.details ?? []
}
