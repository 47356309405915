import { SetupAccountSection } from './SetupAccountReducer'
import { useAppSelector } from '../../Hooks'

const NextActiveAccountSetupSection = (): SetupAccountSection | null => {
  const { completedSignup, completedBusinessInformationSection, completedIdentitySection, completedBankDetailsSection, completedLoyaltyPercentageSection } =
    useAppSelector(({ setupAccountReducer: reducer }) => reducer)

  if (completedSignup) {
    return null
  }

  if (!completedBusinessInformationSection) {
    return 'businessInformation'
  }
  if (completedBusinessInformationSection && !completedIdentitySection) {
    return 'identity'
  }
  if (completedBusinessInformationSection && completedIdentitySection && !completedBankDetailsSection) {
    return 'bankDetails'
  }
  if (completedBusinessInformationSection && completedIdentitySection && completedBankDetailsSection && !completedLoyaltyPercentageSection) {
    return 'loyaltyPercentage'
  }

  return null
}

export default NextActiveAccountSetupSection
