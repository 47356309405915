import { AppUser } from '../../../models/models'
import { AuthActions } from './AuthActionCreators'

export const LOCAL_STORAGE_CURRENT_USER_KEY = 'currentUser'
const user: AppUser = JSON.parse(<string>localStorage.getItem(LOCAL_STORAGE_CURRENT_USER_KEY))

const AuthReducer = (state: AuthState = AuthInitialState, action: AuthActions) => {
  switch (action.type) {
    case 'resetStoreAuth':
      return AuthInitialState
    case 'loginRequest':
      return {
        ...state,
        loggingInError: null,
        loggingIn: true,
      }
    case 'loginSuccess':
      return {
        ...state,
        user: action.user,
        loggingIn: false,
        loggingInError: null,
        loggedIn: true,
      }
    case 'loginFailure':
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        loggingInError: { error: action.error, description: action.description },
      }
    case 'logout':
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        user: null,
      }

    default:
      return state
  }
}

export type AuthState = {
  loggingIn: boolean
  loggedIn: boolean
  user: AppUser | null
  loggingInError: { error: string; description: string } | null
}

const AuthInitialState: AuthState = {
  loggingIn: false,
  loggedIn: !!user,
  user,
  loggingInError: null,
}

export { AuthReducer }
