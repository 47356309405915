import React, { Dispatch, FC, useEffect, useRef } from 'react'
import { useImmerReducer } from 'use-immer'
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GenericInformationCard, InformationCard, Label3XLarge, PrimaryButton } from '@pixieme/pixie-react-components'
import {
  DiscoverySetupActions,
  DiscoverySetupInitialState,
  DiscoverySetupReducer,
  DiscoverySetupState
} from './context/DiscoverySetupReducer'
import DiscoveryDetailsBasics from './components/DiscoveryDetailsBasics'
import DiscoveryDetailsClassification from './components/DiscoveryDetailsClassification'
import DiscoveryDetailsPhotos from './components/DiscoveryDetailsPhotos'
import DiscoveryDetailsCopy from './components/DiscoveryDetailsCopy'
import { useAppSelector } from '../../../redux/Hooks'
import { GetDiscoveryStatusAction } from '../../../redux/reducers/SetupDiscovery/SetupDiscoveryActionCreators'
import { GetSignupStatusAction } from '../../../redux/reducers/SetupAccount/SetupAccountActionCreators'
import NextActiveDiscoverySetupSection from '../../../redux/reducers/SetupDiscovery/SetupDiscoveryUtils'
import Logger from '../../../utils/logger'
import AppConfig from '../../../config/appConfig'
import { TradingType } from '../../../models/models'

export const DiscoverySetupStateContext = React.createContext<DiscoverySetupState>(DiscoverySetupInitialState)
export const DiscoverySetupDispatchContext = React.createContext<Dispatch<DiscoverySetupActions>>({} as Dispatch<DiscoverySetupActions>)

const DiscoverySetupPage = () => {
  const { completedSignup } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)
  const {
    completedDiscovery,
    completedBasicsSection,
    completedClassificationSection,
    completedPhotosSection,
    completedCopySection,
    businessTradingType,
    status: { savedClassification, savedBasics, savedBioPhoto, savedCopy, savedPhotos },
  } = useAppSelector(({ setupDiscoveryReducer: reducer }) => reducer)
  const [state, dispatch] = useImmerReducer(DiscoverySetupReducer, DiscoverySetupInitialState)
  const appDispatch = useDispatch()

  useEffect(() => {
    appDispatch(GetDiscoveryStatusAction())
    appDispatch(GetSignupStatusAction())
    appDispatch({ type: 'selectSideMenuItem', sideMenuItemType: 'setupDiscovery' })
  }, [savedClassification, savedBasics, savedBioPhoto, savedCopy, savedPhotos, businessTradingType])

  return (
    <DiscoverySetupDispatchContext.Provider value={dispatch}>
      <DiscoverySetupStateContext.Provider value={state}>
        <div className="space-y-6 px-2 md:max-w-5xl mx-auto">
          <Label3XLarge variant="dark" label="Business Profile Setup" additionalCss="font-extrabold tracking-tight" />
          {!completedDiscovery ? (
            <SignupPage
              completedSignup={completedSignup}
              completedBasicsSection={completedBasicsSection}
              completedCopySection={completedCopySection}
              completedClassificationSection={completedClassificationSection}
              completedPhotosSection={completedPhotosSection}
              businessTradingType={businessTradingType}
            />
          ) : (
            <CompletedSignup />
          )}
        </div>
      </DiscoverySetupStateContext.Provider>
    </DiscoverySetupDispatchContext.Provider>
  )
}

export default DiscoverySetupPage

const SignupPage: FC<SignupPageProps> = ({
  completedSignup,
  completedBasicsSection,
  completedClassificationSection,
  completedPhotosSection,
  completedCopySection,
  businessTradingType,
}) => {
  const basicsRef = useRef<HTMLDivElement>(null)
  const classificationRef = useRef<HTMLDivElement>(null)
  const photosRef = useRef<HTMLDivElement>(null)
  const copyRef = useRef<HTMLDivElement>(null)
  const nextActiveDiscoverySetupSection = NextActiveDiscoverySetupSection()

  const scrollIntoView = () => {
    /* Ignoring scrolling the Basics section as this will hide header info otherwise */
    if (nextActiveDiscoverySetupSection === 'classification') {
      classificationRef.current?.scrollIntoView(true)
    } else if (nextActiveDiscoverySetupSection === 'photos') {
      photosRef.current?.scrollIntoView(true)
    } else if (nextActiveDiscoverySetupSection === 'copy') {
      copyRef.current?.scrollIntoView(true)
    }

    Logger.info('scrollIntoView')
  }

  useEffect(() => {
    setTimeout(() => {
      scrollIntoView()
    }, 200)
  }, [
    completedSignup,
    completedBasicsSection,
    completedClassificationSection,
    completedPhotosSection,
    completedCopySection,
    businessTradingType])

  return (
    <>
      <InformationCard
        label={
          "Tell us about your business so that we can add it to the Pixie Directory. Each section is saved as you complete it, so you don't need to fill it all out at once."
        }
        icon={faInfoCircle}
        iconSize="2x"
      />
      <DiscoveryDetailsBasics
        ref={basicsRef}
        completed={completedBasicsSection}
        enabled={nextActiveDiscoverySetupSection === 'basics'}
        showOpeningHours={businessTradingType === TradingType.BricksAndMortar}
      />
      <DiscoveryDetailsClassification
        ref={classificationRef}
        completed={completedClassificationSection}
        enabled={nextActiveDiscoverySetupSection === 'classification'}
      />
      {businessTradingType === TradingType.BricksAndMortar && (
        <>
          <DiscoveryDetailsPhotos ref={photosRef} completed={completedPhotosSection} enabled={nextActiveDiscoverySetupSection === 'photos'} />
          <DiscoveryDetailsCopy ref={copyRef} completed={completedCopySection} enabled={nextActiveDiscoverySetupSection === 'copy'} />
        </>
      )}
    </>
  )
}

const CompletedSignup = () => {
  const history = useHistory()

  return (
    <div className="flex flex-col sm:items-start space-y-6 mx-2" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
      <GenericInformationCard
        backgroundColor="bg-white"
        iconVariant="success"
        iconSize="2x"
        icon={faCheckCircle}
        labelVariant="dark"
        label="You've added your discovery details. The Pixie Team will let you know as soon as your profile has been completed"
        additionalCss="py-6 w-full"
      />
      <PrimaryButton label="Return to Dashboard" size="small" roundedType="full" onClick={() => history.push('/')} />
    </div>
  )
}

/* types */
type SignupPageProps = {
  completedSignup: boolean
  completedBasicsSection: boolean
  completedClassificationSection: boolean
  completedPhotosSection: boolean
  completedCopySection: boolean
  businessTradingType: TradingType
}
