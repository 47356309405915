import React, { Dispatch, FC, useEffect, useRef } from 'react'
import { useImmerReducer } from 'use-immer'
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GenericCardWithHeader, InformationCard, Label3XLarge, PrimaryButton, TransparentButton } from '@pixieme/pixie-react-components'
import { AccountSetupActions, AccountSetupInitialState, AccountSetupReducer, AccountSetupState } from './context/AccountSetupReducer'
import AccountDetailsBusinessInformation from './components/AccountDetailsBusinessInformation'
import AccountDetailsProofOfIdentity from './components/AccountDetailsProofOfIdentity'
import AccountDetailsBankDetails from './components/AccountDetailsBankDetails'
import AccountDetailsLoyaltyPercent from './components/AccountDetailsLoyaltyPercent'
import { GetSignupStatusAction } from '../../../redux/reducers/SetupAccount/SetupAccountActionCreators'
import { useAppSelector } from '../../../redux/Hooks'
import NextActiveAccountSetupSection from '../../../redux/reducers/SetupAccount/SetupAccountUtils'
import Logger from '../../../utils/logger'
import AppConfig from '../../../config/appConfig'

export const AccountSetupStateContext = React.createContext<AccountSetupState>(AccountSetupInitialState)
export const AccountSetupDispatchContext = React.createContext<Dispatch<AccountSetupActions>>({} as Dispatch<AccountSetupActions>)

const AccountSetupPage = () => {
  const {
    completedSignup,
    completedBusinessInformationSection,
    completedIdentitySection,
    completedBankDetailsSection,
    completedLoyaltyPercentageSection,
    status: { savedBankDetails, savedBusinessInformation, savedLoyaltyPercentage, savedProofOfIdentity },
  } = useAppSelector(({ setupAccountReducer: reducer }) => reducer)

  const [localState, localDispatch] = useImmerReducer(AccountSetupReducer, AccountSetupInitialState)
  const appDispatch = useDispatch()

  useEffect(() => {
    appDispatch({ type: 'selectSideMenuItem', sideMenuItemType: 'setupAccount' })
    appDispatch(GetSignupStatusAction())
  }, [savedBankDetails, savedBusinessInformation, savedLoyaltyPercentage, savedProofOfIdentity])

  return (
    <AccountSetupDispatchContext.Provider value={localDispatch}>
      <AccountSetupStateContext.Provider value={localState}>
        <div className="space-y-6 px-2 md:max-w-5xl mx-auto">
          {!completedSignup ? (
            <Signup
              completedBusinessInformationSection={completedBusinessInformationSection}
              completedBankDetailsSection={completedBankDetailsSection}
              completedIdentitySection={completedIdentitySection}
              completedLoyaltyPercentageSection={completedLoyaltyPercentageSection}
            />
          ) : (
            <CompletedSignup />
          )}
        </div>
      </AccountSetupStateContext.Provider>
    </AccountSetupDispatchContext.Provider>
  )
}

export default AccountSetupPage

const Signup: FC<SignupProps> = ({
  completedBusinessInformationSection,
  completedIdentitySection,
  completedBankDetailsSection,
  completedLoyaltyPercentageSection,
}) => {
  const businessInformationRef = useRef<HTMLDivElement>(null)
  const proofOfIdentityRef = useRef<HTMLDivElement>(null)
  const bankDetailsRef = useRef<HTMLDivElement>(null)
  const loyaltyPercentRef = useRef<HTMLDivElement>(null)
  const nextActiveSetupAccountSection = NextActiveAccountSetupSection()

  const scrollIntoView = () => {
    /* Ignoring scrolling the Business Information section as this will hide header info otherwise */
    if (nextActiveSetupAccountSection === 'identity') {
      proofOfIdentityRef.current?.scrollIntoView(true)
    } else if (nextActiveSetupAccountSection === 'bankDetails') {
      bankDetailsRef.current?.scrollIntoView(true)
    } else if (nextActiveSetupAccountSection === 'loyaltyPercentage') {
      loyaltyPercentRef.current?.scrollIntoView(true)
    }
    Logger.info('scrollIntoView')
  }

  useEffect(() => {
    setTimeout(() => {
      scrollIntoView()
    }, 200)
  }, [completedBusinessInformationSection, completedIdentitySection, completedBankDetailsSection, completedLoyaltyPercentageSection])

  return (
    <>
      <Label3XLarge variant="dark" label="Account Setup" additionalCss="font-extrabold tracking-tight" />
      <InformationCard
        label={
          "Please set up your merchant account with Pixie by completing this form. Each section is saved as you complete it, so you don't need to fill it all out at once."
        }
        icon={faInfoCircle}
        iconSize="2x"
      />
      <AccountDetailsBusinessInformation
        ref={businessInformationRef}
        completed={completedBusinessInformationSection}
        enabled={nextActiveSetupAccountSection === 'businessInformation'}
      />
      <AccountDetailsProofOfIdentity ref={proofOfIdentityRef} completed={completedIdentitySection} enabled={nextActiveSetupAccountSection === 'identity'} />
      <AccountDetailsBankDetails ref={bankDetailsRef} completed={completedBankDetailsSection} enabled={nextActiveSetupAccountSection === 'bankDetails'} />
      <AccountDetailsLoyaltyPercent
        ref={loyaltyPercentRef}
        completed={completedLoyaltyPercentageSection}
        enabled={nextActiveSetupAccountSection === 'loyaltyPercentage'}
      />
    </>
  )
}

const CompletedSignup = () => {
  const history = useHistory()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      ref.current?.scrollIntoView(true)
    }, 200)
  }, [])

  return (
    <div ref={ref} style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
      <div className="flex flex-col items-start space-y-6 pb-8 mx-2">
        <Label3XLarge variant="dark" label="Account Setup" additionalCss="font-extrabold tracking-tight" />
        <GenericCardWithHeader
          backgroundColor="bg-white"
          labelVariant="dark"
          iconVariant="success"
          headerVariant="dark"
          header="You've completed step 1"
          label="Thanks for telling us about your business. Continue to step 2 and tell us more about what you do."
          icon={faCheckCircle}
          iconSize="2x"
          additionalCss="w-full sm"
        />
        <div className="flex flex-col w-full sm:flex-row mt-8 sm:space-x-6 space-y-4 sm:space-y-0">
          <PrimaryButton label="Continue to step 2" size="small" roundedType="full" onClick={() => history.push('/setup/business-profile-setup')} />
          <TransparentButton
            label="Return to Dashboard"
            size="small"
            roundedType="full"
            additionalCss="border border-primary"
            additionalLabelCss="text-primary"
            onClick={() => history.push('/')}
          />
        </div>
      </div>
    </div>
  )
}

/* type */
type SignupProps = {
  completedBusinessInformationSection: boolean
  completedIdentitySection: boolean
  completedBankDetailsSection: boolean
  completedLoyaltyPercentageSection: boolean
}
