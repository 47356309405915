import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import { ButtonRoundedType, ButtonSizeType, VariantBackgroundColor, VariantBackgroundDisabledColor, VariantType } from '@pixieme/pixie-react-components'

const BusyButton: FC<BusyButtonProps> = ({
  isBusy,
  label,
  size,
  onClick,
  roundedType,
  additionalCss,
  additionalLabelCss,
  variant = 'primary',
  buttonType = 'submit',
  disabled = false,
}) => {
  const roundedStyle = () => {
    switch (roundedType) {
      case 'none':
        return 'rounded-none'
      case 'small':
        return 'rounded-sm'
      case 'medium':
        return 'rounded-md'
      case 'large':
        return 'rounded-lg'
      case 'full':
        return 'rounded-full'
      default:
        return 'rounded-none'
    }
  }

  const sizeStyle = () => {
    switch (size) {
      case 'small':
        return 'px-4 py-2 text-sm'
      case 'medium':
        return 'px-4 py-2 text-base'
      case 'large':
        return 'px-6 py-2 text-xl'
      default:
        return 'px-4 py-2 text-base'
    }
  }

  /* eslint-disable react/button-has-type */
  return (
    <button
      type={buttonType}
      disabled={disabled || isBusy}
      onClick={onClick}
      className={[
        'text-white font-bold focus:outline-none',
        roundedStyle(),
        disabled || isBusy ? VariantBackgroundDisabledColor(variant) : VariantBackgroundColor(variant),
        sizeStyle(),
        additionalCss,
      ].join(' ')}>
      <div className="flex space-x-2 items-center">
        {isBusy && <FontAwesomeIcon icon={faSpinner} size="1x" color="text-white" spin />}
        <span className={additionalLabelCss}>{label}</span>
      </div>
    </button>
  )
}

export default BusyButton

type BusyButtonProps = {
  isBusy: boolean
  buttonType?: 'submit' | 'button' | 'reset'
  variant?: VariantType
  label: string
  size: ButtonSizeType
  roundedType?: ButtonRoundedType
  onClick?: (e: React.SyntheticEvent) => void
  additionalCss?: string
  additionalLabelCss?: string
  disabled?: boolean
}
