import produce from 'immer'
import { SetupDiscoveryActions } from './SetupDiscoveryActionCreators'
import { ApiError, TradingType } from '../../../models/models'
import { BusinessCategory, FormValidationApError } from '../../../services/pixie/Models/ApiModels'

const SetupDiscoveryReducer = (state: SetupDiscoveryState = SetupDiscoveryInitialState, action: SetupDiscoveryActions) => {
  switch (action.type) {
    case 'resetStoreSetupDiscovery':
      return SetupDiscoveryInitialState
    case 'getDiscoveryStatusRequest':
      return produce(state, (draft) => {
        draft.status.gettingDiscoveryStatus = true
      })
    case 'getDiscoveryStatusSuccess':
      return produce(state, (draft) => {
        draft.completedBasicsSection = action.discoveryStatus.haveCompletedBasics
        draft.completedCopySection = action.discoveryStatus.haveCompletedCopy
        draft.completedDiscovery = action.discoveryStatus.haveCompletedDiscovery
        draft.completedClassificationSection = action.discoveryStatus.haveCompletedClassification
        draft.completedPhotosSection = action.discoveryStatus.haveCompletedPhotos
        draft.businessTradingType = action.discoveryStatus.businessTradingType
        draft.status.gettingDiscoveryStatus = false
      })
    case 'getDiscoveryStatusFailure':
      return produce(state, (draft) => {
        draft.status.gettingDiscoveryStatus = false
      })
    case 'resetBasics':
      return produce(state, (draft) => {
        draft.status.addingBasicsApiError = null
        draft.status.addingBasics = false
        draft.status.savedBasics = false
      })
    case 'addBasicsRequest':
      return produce(state, (draft) => {
        draft.status.addingBasics = true
        draft.status.addingBasicsFormValidationApiErrors = []
        draft.status.addingBasicsApiError = null
        draft.status.savedBasics = false
      })
    case 'addBasicsSuccess':
      return produce(state, (draft) => {
        draft.status.addingBasicsFormValidationApiErrors = []
        draft.status.addingBasicsApiError = null
        draft.status.addingBasics = false
        draft.status.savedBasics = true
      })
    case 'addBasicsFailure':
      return produce(state, (draft) => {
        draft.status.addingBasicsFormValidationApiErrors = []
        draft.status.addingBasicsApiError = { code: action.error, description: action.description } as ApiError
        draft.status.addingBasics = false
        draft.status.savedBasics = false
      })
    case 'addBasicsFormValidationErrors':
      return produce(state, (draft) => {
        draft.status.addingBasicsFormValidationApiErrors = []
        draft.status.addingBasicsFormValidationApiErrors.push(action.error)
        draft.status.addingBasics = false
        draft.status.savedBasics = false
      })
    case 'addClassificationRequest':
      return produce(state, (draft) => {
        draft.status.addingClassification = true
      })
    case 'addClassificationSuccess':
      return produce(state, (draft) => {
        draft.status.savedClassification = true
        draft.status.addingClassification = false
      })
    case 'addClassificationFailure':
      return produce(state, (draft) => {
        draft.status.savedClassification = false
        draft.status.addingClassification = false
      })
    case 'addPhotosRequest':
      return produce(state, (draft) => {
        draft.status.addingPhotos = true
      })
    case 'addPhotosSuccess':
      return produce(state, (draft) => {
        draft.status.savedPhotos = true
        draft.status.addingPhotos = false
      })
    case 'addPhotosFailure':
      return produce(state, (draft) => {
        draft.status.savedPhotos = false
        draft.status.addingPhotos = false
      })
    case 'addCopyRequest':
      return produce(state, (draft) => {
        draft.status.addingCopy = true
      })
    case 'addCopySuccess':
      return produce(state, (draft) => {
        draft.status.savedCopy = true
        draft.status.addingCopy = false
      })
    case 'addCopyFailure':
      return produce(state, (draft) => {
        draft.status.savedCopy = false
        draft.status.addingCopy = false
      })
    case 'addBioPhotoRequest':
      return produce(state, (draft) => {
        draft.status.addingBioPhoto = true
      })
    case 'addBioPhotoSuccess':
      return produce(state, (draft) => {
        draft.status.savedBioPhoto = true
        draft.status.addingBioPhoto = false
      })
    case 'addBioPhotoFailure':
      return produce(state, (draft) => {
        draft.status.savedBioPhoto = false
        draft.status.addingBioPhoto = false
      })
    case 'getBusinessCategoriesRequest':
      return produce(state, (draft) => {
        draft.businessCategoriesLoading = true
      })
    case 'getBusinessCategoriesSuccess':
      return produce(state, (draft) => {
        draft.businessCategories = []
        draft.businessCategories.push(...action.categories)
        draft.businessCategoriesLoading = false
      })
    case 'getBusinessCategoriesFailure':
      return produce(state, (draft) => {
        draft.businessCategories = []
        draft.businessCategoriesLoading = false
      })
    default:
      return produce(state, () => {})
  }
}

export type DiscoveryStatusType = {
  gettingDiscoveryStatus: boolean
  addingBasics: boolean
  addingClassification: boolean
  addingPhotos: boolean
  addingCopy: boolean
  addingBioPhoto: boolean
  savedBasics: boolean
  savedClassification: boolean
  savedPhotos: boolean
  savedCopy: boolean
  savedBioPhoto: boolean
  addingBasicsApiError: ApiError | null
  addingBasicsFormValidationApiErrors: FormValidationApError[]
}

const DiscoveryStatusTypeInitialState: DiscoveryStatusType = {
  gettingDiscoveryStatus: false,
  addingBasics: false,
  addingClassification: false,
  addingPhotos: false,
  addingCopy: false,
  addingBioPhoto: false,
  savedBasics: false,
  savedClassification: false,
  savedPhotos: false,
  savedCopy: false,
  savedBioPhoto: false,
  addingBasicsApiError: null,
  addingBasicsFormValidationApiErrors: [],
}

export type SetupDiscoveryState = {
  status: DiscoveryStatusType
  completedBasicsSection: boolean
  completedClassificationSection: boolean
  completedPhotosSection: boolean
  completedCopySection: boolean
  completedDiscovery: boolean
  businessCategoriesLoading: boolean
  businessCategories: BusinessCategory[]
  businessTradingType: TradingType
}

const SetupDiscoveryInitialState: SetupDiscoveryState = {
  status: DiscoveryStatusTypeInitialState,
  completedBasicsSection: false,
  completedClassificationSection: false,
  completedPhotosSection: false,
  completedCopySection: false,
  completedDiscovery: false,
  businessCategoriesLoading: false,
  businessCategories: [],
  businessTradingType: TradingType.BricksAndMortar,
}

export type SetupDiscoverySection = 'basics' | 'classification' | 'photos' | 'copy'

export { SetupDiscoveryReducer, SetupDiscoveryInitialState }
