import React from 'react'
import { useDispatch } from 'react-redux'
import { LabelSmall } from '@pixieme/pixie-react-components'
import SetupHeaderCard from '../../../../appComponents/SetupHeader/SetupHeaderCard'
import { AddCopyAction } from '../../../../redux/reducers/SetupDiscovery/SetupDiscoveryActionCreators'
import BusyButton from '../../../../appComponents/buttons/AppButtons'
import { useAppSelector } from '../../../../redux/Hooks'
import AppConfig from '../../../../config/appConfig'

const DiscoveryDetailsCopy = React.forwardRef<HTMLDivElement, DiscoveryDetailsCopyProps>(({ enabled, completed }, ref) => (
  <div ref={ref} className="bg-white w-full shadow-sm rounded-md" style={{ scrollMarginTop: AppConfig.navBarScrollMarginTop }}>
    <SetupHeaderCard completed={completed} enabled={enabled} labelLeft="Tell us about your business" labelRight="Step 4 of 4" openState="closed">
      <DiscoveryDetailsCopyForm />
    </SetupHeaderCard>
  </div>
))

export default DiscoveryDetailsCopy

const DiscoveryDetailsCopyForm = () => {
  const {
    status: { addingCopy },
  } = useAppSelector(({ setupDiscoveryReducer: reducer }) => reducer)

  const appDispatch = useDispatch()
  const message = 'To be completed by Pixie copywriter'

  return (
    <div className="px-4 pb-6">
      <LabelSmall
        variant="dark"
        label="Your Pixie Profile will be created by one of our professional copywriters. A member of the Pixie team will be in touch to discuss this in more detail."
      />
      <BusyButton
        buttonType="button"
        isBusy={addingCopy}
        label="Continue"
        size="small"
        roundedType="full"
        additionalCss="mt-6"
        onClick={() => appDispatch(AddCopyAction({ TagLine: message, Description: message, Quote: message, QuoteAuthor: message }))}
      />
    </div>
  )
}

/* types */
type DiscoveryDetailsCopyProps = {
  enabled: boolean
  completed: boolean
}
