import { OpeningHourLineModel } from '@pixieme/pixie-react-components'
import { OpeningHours } from '../services/pixie/Models/ApiModels'

export default class OpeningHoursParser {
  parse = (openingHoursData?: OpeningHours): OpeningHourLineModel[] => {
    const openingHours = [] as OpeningHourLineModel[]

    this.addOpeningHours(openingHours, this.getStringForOpeningHours(1, openingHoursData?.openingHoursMonday))
    this.addOpeningHours(openingHours, this.getStringForOpeningHours(2, openingHoursData?.openingHoursTuesday))
    this.addOpeningHours(openingHours, this.getStringForOpeningHours(3, openingHoursData?.openingHoursWednesday))
    this.addOpeningHours(openingHours, this.getStringForOpeningHours(4, openingHoursData?.openingHoursThursday))
    this.addOpeningHours(openingHours, this.getStringForOpeningHours(5, openingHoursData?.openingHoursFriday))
    this.addOpeningHours(openingHours, this.getStringForOpeningHours(6, openingHoursData?.openingHoursSaturday))
    this.addOpeningHours(openingHours, this.getStringForOpeningHours(7, openingHoursData?.openingHoursSunday))

    return openingHours
  }

  private getStringForOpeningHours = (day: number, dailyOpeningHoursString?: string): OpeningHourLineModel[] | null => {
    if (!dailyOpeningHoursString || dailyOpeningHoursString.length === 0) {
      return null
    }
    const openingHours = [] as OpeningHourLineModel[]
    const res = dailyOpeningHoursString.toLowerCase().matchAll(/(\d{1,2}:\d{2})(am|pm)\s*?-\s*(\d{1,2}:\d{2})(am|pm)/g)

    if (!res) {
      return null
    }

    Array.from(res).forEach((result) => {
      result = result.slice(1)
      const correctOpenTimeFormat = this.convertTime12to24(`${result[0]}${result[1]}`)
      const correctCloseTimeFormat = this.convertTime12to24(`${result[2]}${result[3]}`)
      openingHours.push({ Day: day, FromTime: correctOpenTimeFormat, ToTime: correctCloseTimeFormat } as OpeningHourLineModel)
    })

    return openingHours
  }

  private addOpeningHours = (openingHours: OpeningHourLineModel[], openingHoursForDay: OpeningHourLineModel[] | null) => {
    if (openingHoursForDay) {
      openingHours.push(...openingHoursForDay)
    }
  }

  // TODO: - will come back to this.
  private convertTime12to24 = (time12h: string) => time12h
}
